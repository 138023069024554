import React, { useState, useEffect, useContext } from "react";
import "./Card.css";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";
import { UilTimes, UilKeySkeleton } from "@iconscout/react-unicons"
import Chart from "react-apexcharts";
import axios from "axios";
import DataContext from "../../../../context/DataContext";

// parent Card

const KeyCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  

  return (
    <AnimateSharedLayout>
      {expanded ? (
        // <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
        <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
      ) : (
        <CompactCard param={props} setExpanded={() => setExpanded(true)} />
      )}
    </AnimateSharedLayout>
  );
};

// Compact Card
function CompactCard({ param, setExpanded }) {

  return (
    <motion.div
      className={param.color === 'purple' ? "CompactCard purpleCard" : param.color === 'green' ? "CompactCard greenCard" : "CompactCard pinkCard"}
      layoutId="expandableCard"
      onClick={setExpanded}
    >
      <div className="title">
        <span>{param.title}</span>
        <span><param.icon /></span>
      </div>
      <div className="contents">
        <div className="radialBar">
          <span>Available</span>
          <span>Generated</span>
          <span>Total</span>
        </div>
        <div className="detail">
          <span>{(param.available)}</span>
          <span>{(param.used)}</span>
          <span>{(param.total)}</span>
        </div>
      </div>
    </motion.div>
  );
}


const VIEW_ALL_KEYS = process.env.REACT_APP_API_GET_KEY_DETAILS_OWNER_ALL; //process.env.REACT_APP_API_GET_KEY_OWNER_ALL;

// Expanded Card
const currentMonth = new Date().getMonth(); // Get the current month (0-based index)
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const categories = monthNames.slice(0, currentMonth + 1);


function ExpandedCard({ param, setExpanded }) {
  
  const {  sellerId } = useContext(DataContext)
  const availableData = Array(currentMonth + 1).fill(0);
  availableData[currentMonth] = param.available;
  const usedData = Array(currentMonth + 1).fill(0);
  usedData[currentMonth] = param.used;
  
  availableData[currentMonth] = param.available;
  
  usedData[currentMonth] = param.used;

  const categories = monthNames.slice(0, currentMonth + 1);

  const [generatedData, setGeneratedData] = useState(Array(currentMonth + 1).fill(0));
  const [permanentGeneratedData, setPermanentGeneratedData] = useState(Array(currentMonth + 1).fill(0));
  const [temporaryGeneratedData, setTemporaryGeneratedData] = useState(Array(currentMonth + 1).fill(0));
  const [perpetualGeneratedData, setPerpetualGeneratedData] = useState(Array(currentMonth + 1).fill(0));

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${VIEW_ALL_KEYS}/${sellerId}`);
        const keyAssignments = response.data.result;
  
        //console.log('Raw Key Assignments:', keyAssignments);
  
        // Initialize arrays to store counts of keys generated per month
        const generatedKeysCount = Array(12).fill(0); // For each month of the year
        const permanentKeysCount = Array(currentMonth + 1).fill(0);
        const temporaryKeysCount = Array(currentMonth + 1).fill(0);
        const perpetualKeysCount = Array(currentMonth + 1).fill(0);
  
        keyAssignments.forEach((assignment) => {
          const createdDate = new Date(assignment.createdDate);
          const month = createdDate.getMonth(); // Get month (0 = Jan, 11 = Dec)
          const keyType = assignment.keyOwner?.keyGen?.type;
  
          if (keyType === "PERMANENT") {
            permanentKeysCount[month]++;
          } else if (keyType === "TEMPORARY") {
            temporaryKeysCount[month]++;
          } else if (keyType === "PERPETUAL") {
            perpetualKeysCount[month]++;
          }
  
          // Count total keys regardless of type
          generatedKeysCount[month]++;
        });
  
        setGeneratedData(generatedKeysCount);
        setPermanentGeneratedData(permanentKeysCount);
        setTemporaryGeneratedData(temporaryKeysCount);
        setPerpetualGeneratedData(perpetualKeysCount);
  
        //console.log('Generated Keys by Month:', generatedKeysCount);
        //console.log('Permanent Keys by Month:', permanentKeysCount);
        //console.log('Temporary Keys by Month:', temporaryKeysCount);
        //console.log('Perpetual Keys by Month:', perpetualKeysCount);
  
      } catch (error) {
        console.error("Error fetching key assignments:", error);
      }
    }
  
    fetchData();
  }, [sellerId]);
  
  
  

  
  
  //console.log("Generated Data After Effect:", generatedData);



  const permanentData = {


    series: [

    
      {
        name: "Generated Keys",
        data: permanentGeneratedData,
      },
     
      {
        name: "Available Keys",
        data: availableData,

      },

      


    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: "auto",
      },
      colors: ["#d1c4e9", '#f3e5f5', "#eeebd3"],
      fill: {
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: monthNames, // Use month names as categories
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
      },
    },
  };

  const perpetualData = {


    series: [

      
      {
        name: "Generated Perpetual Keys",
        data: perpetualGeneratedData,
      },
     
      {
        name: "Available Perpetual Keys",
        data: availableData,

      },

      


    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: "auto",
      },
      colors: ["#d1c4e9"],

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: ["#d1c4e9", '#f3e5f5', "#eeebd3" ],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["white"],
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: monthNames,
        tickAmount: categories.length,
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
      },
    },
  };

  const temporaryData = {


    series: [

    
      {
        name: "Generated Keys",
        data: temporaryGeneratedData,
      },
     
      {
        name: "Available Keys",
        data: availableData,

      },

      

    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: "auto",
      },
      colors: ["#d1c4e9", '#f3e5f5', "#eeebd3"],

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: [ '#f3e5f5'],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["white"],
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: monthNames,
        tickAmount: categories.length,
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
      },
    },
  };
  const selectedChartData = param.color === 'purple' ? permanentData : param.color === 'green' ? perpetualData : temporaryData;

  return (
    <motion.div
    className={param.color === 'purple' ? "ExpandedCard purpleCard"  : param.color === 'green' ? "ExpandedCard greenCard" : "ExpandedCard pinkCard"}
      layoutId="expandableCard"
    >
      <div style={{ alignSelf: "flex-end", cursor: "pointer", color: "black" }}>
        <UilTimes onClick={setExpanded} />
      </div>
      <span>{param.title}</span>
      <div className="chartContainer">
        <Chart
          options={selectedChartData.options}
          series={selectedChartData.series}
          type="bar"
          
          style={{ overflow: 'visible' }}
        />
        


      </div>
      <span></span>
      <span></span>
    </motion.div>
  );
}

export default KeyCard;
