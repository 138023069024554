import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useCallback, useContext } from "react";
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import SnackbarAlerts from "../SnackbarAlerts";
import ConfirmDialog from "../ConfirmDialog";
import { KeyViewSearch, /* KeyTypes, */ KeyTypeForManage, FilterByUsers, keyStatus, FilterDisabledKeys, FilterByKeys } from "../../Data/Data"
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import { TbFileDescription } from 'react-icons/tb';
import KeyViewPopup from "./KeyViewPopup";
import KeyAssignHistoryPopup from "./KeyAssignHistoryPopup";
import KeyHistoryPopup from "./KeyHistoryPopup";
import KeyDetailsPopup from "./KeyDetailsPopup";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, Variables } from '../../Data/Data'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import EditUUIDPopup from "./EditUUIDPopup";
import { Row, Col, Tooltip } from 'react-bootstrap'; //Container, Card, ---never used
import { FaEdit } from 'react-icons/fa';
import "./ManageTable.css";


const KEY_LIST = process.env.REACT_APP_API_GET_KEYS_LIST;

const SELLER_LIST = process.env.REACT_APP_API_SELLER_ID;//Seller ID
const ASSIGN_DE_ACTIVE = process.env.REACT_APP_API_KEY_DISABLE_ASSIGN_KEY;
const GENERATE_DE_ACTIVE = process.env.REACT_APP_API_KEY_DISABLE_GENERATED_KEY;
//onst ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const CHECK_KEY_EXTEND = process.env.REACT_APP_API_CHECK_KEY_EXTEND;
const CHECK_UUIDS = process.env.REACT_APP_API_CHECK_UUID;
const ENABLE_KEY = process.env.REACT_APP_API_ENABLE_KEY;
//const CUSTOMER_BY_SELLER = process.env.REACT_APP_API_CUSTOMER_FIND_BY_SELLER_ID;
const CUSTOMER_KEY = process.env.REACT_APP_API_CUSTOMER_KEY;
const KEY_HISTORY = process.env.REACT_APP_API_KEY_HISTORY;
const ASSIGN_KEY_HISTORY = process.env.REACT_APP_API_KEY_ASSIGN_HISTORY;
const KEY_DELETE = `${process.env.REACT_APP_API_URL}/api/key/`;


const Table = (props) => {

  let variant


  const { setUuidEdit, uuidEdit, setKeyGenId, keyGenId, setValue, licenseType, setLicenseType } = useContext(KeyGenData)
  const { uuidSearch, setUuidSearch, userSearch, setUserSearch, keyListPrint, sellerKeys, setSellerKeys, fullName, setKeyListPrint, isExtendedKey, setIsExtendedKey, transferCustomerId, customerKeys, setCustomerKeys, sellerSearch, customerSearch, setCustomerSearch, disabledKeyGenIdCustomer, setDisabledKeyGenIdCustomer, activeKeyGenIdCustomer, setActiveKeyGenIdCustomer, activeKeyGenIdSeller, setActiveKeyGenIdSeller, setDisabledKeyGenIdSeller, disabledKeyGenIdSeller, setSellerSearch, actionView, setActionView, userName, setUserName } = useContext(DataContext) //adminAccess, partnerAccess, setAdminAccess, --never used
  const { editKeyAccess, extendKeyAccess, updateKeyAccess, disableKeyAccess, printAccess, isKeyDeleted, enableKeyAccess } = useContext(AuthProvider) //adminAccess, partnerAccess, setAdminAccess, --never used
  const [uuids, setUUIDs] = useState([]);
  const [val, setVal] = useState('0')
  const [refreshTable, setRefreshTable] = useState(false);
  //const [sellerValue, setSellerValue] = useState('') -- never used
  //const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [expiredKeys, setExpiredKeys] = useState([]);
  const [expiredKeyId, setExpiredKeyId] = useState('');
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [extendedKeyGenIds, setExtendedKeyGenIds] = useState([]);


  //const [selectedKeyDetails, setSelectedKeyDetails] = useState(null); --never used
  const [openPopup, setOpenPopup] = useState(false);
  const [disabledKeyGenIds, setDisabledKeyGenIds] = useState("");
  const [activeKeyGenIds, setActiveKeyGenIds] = useState([]);
  //const [customers, setCustomers] = useState([]);
  const [isAnyKeyGenerated, setIsAnyKeyGenerated] = useState(false);
  //const [isExtended, setIsExtended] = useState(null); --never used
  const disabledKeyIds = [
    ...(Array.isArray(disabledKeyGenIdCustomer) ? disabledKeyGenIdCustomer.map((key) => key.keyGenId) : []),
    ...(Array.isArray(disabledKeyGenIdSeller) ? disabledKeyGenIdSeller.map((key) => key.keyGenId) : []),
  ];
  //const [type, setType] = useState('1', '0'); 
  const [keyList, setKeyList] = useState([]);


  //const [searchUser, setSearchUser] = useState(''); --never used
  const [searchBy, setSearchBy] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to edit this key?"
  const [openDeActiveA, setOpenDeActiveA] = useState(false);
  const [openDeActiveG, setOpenDeActiveG] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const deleteConfirm = "Are you sure you want to de-active this key?"
  const deleteKeyConfirm = "Are you sure you want to delete this key?"
  const [openExtend, setOpenExtend] = useState(false)
  const extendConfirm = "Are you sure you want to extend this key?"
  const [openEnable, setOpenEnable] = useState(false)
  const enableConfirm = "Are you sure you want to enable this key?"
  const [severity, setServerity] = useState('success');
  const [name, setName] = useState('');
  const [keyType, setKeyType] = useState('');
  const [keyIndex, setKeyIndex] = useState('');

  const [isKeyDisabled, setIsKeyDisabled] = useState(false);
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [disabledFilterKeys, setDisabledFilterKeys] = useState([]);
  const [areCustomerAvailable, setAreCustomerAvailable] = useState(false);
  const [areSellersAvailable, setAreSellersAvailable] = useState(false);

  const [openKeyPopup, setOpenKeyPopup] = useState(false)
  const [openKeyHistoryPopup, setOpenKeyHistoryPopup] = useState(false)
  const [openAssignHistoryPopup, setOpenAssignHistoryPopup] = useState(false)
  const [openEditUUID, setOpenEditUUID] = useState(false)
  //const [userAccessControls, setUserAccessControls] = useState([]); --never used
  const [fetchAccessSuccess, setFetchAccessSuccess] = useState(true); // Default to true
  //const [fetchExtendSuccess, setFetchExtendSuccess] = useState(false);
  //const [fetchUpdateSuccess, setFetchUpdateSuccess] = useState(true);
  //const [fetchEditSuccess, setFetchEditSuccess] = useState(false);
  //const [fetchEnableSuccess, setFetchEnableSuccess] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const maxUserCount = Variables.maxUserCount
  let matchesKeyIndex = false;

  const columns = [
    {
      name: 'UUID',
      selector: row => row.uuid,
      wrap: true,
    },
    {
      name: 'Customer',
      selector: row => row.customerName,
      wrap: true,
      width: '170px',
    },
    {
      name: 'Created on',
      selector: row => row.createdDate,
      width: '85px',
      wrap: true,
    },
    {
      name: 'Valid From',
      selector: row => row.validFrom,
      width: '85px',
    },
    {
      name: 'Valid To',
      selector: row => row.validTo,
      width: '85px',
    },
    {
      name: 'Key Type',
      selector: row => {
        switch (row.keyType) {
          case 'PERMANENT':
            return 'Permanent';
          case 'TEMPORARY':
            return 'Temporary';
          case 'PERPETUAL':
            return 'Perpetual';
          default:
            return 'Unknown'; // Handle any unexpected or undefined key types
        }
      },
      wrap: true,
      width: '100px', // Adjust width as needed
    },
    

    {
      name: 'Days',
      selector: row => row.days,
      width: '50px',
    },

    {
      name: 'User Count',
      selector: row => (row.userCount >= maxUserCount) ? "Unlimited" : row.userCount,
      width: '80px',
    },

    {
      name: 'Description',
      width: '80px',
      selector: row => (
        <div>
          <OverlayTrigger
            trigger={['click', 'hover']}
            delay={{ show: 100, hide: 250 }}
            placement="right"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>{row.description}</Popover.Body>
              </Popover>
            }>
            <div className='p-0 m-0 icon-size'>
              <Button>
                <TbFileDescription />
              </Button>
            </div>
          </OverlayTrigger>
        </div>),
    },
    {
      name: 'Seller Name',
      selector: (row) => row.sellerName, // Use the key from your API response
      wrap: true,
    },

    {
      name: 'Status',
      width: '140px',
      selector: row => (
        <div>
          {row.isGenerated === true ? (
            <div>{getStatusButton(row, activeKeyGenIds, disabledKeyGenIds, disabledKeyGenIdSeller, expiredKeys, handleViewKey)}</div>
          ) : (
            /*  'Assigned' */
            <Button
              className="success-button" // Use the same class as 'Generate' button
              variant="success" // Use the same variant as 'Generate' button
              // Handle the click event
              disabled={true}>
              Assigned
            </Button>
          )}
        </div>
      ),
    },

    {
      name: "Actions",
      button: true,
      // width: '170px',
      cell: (row) => (
        <div className="menu">
          {((row.isGenerated === false && editKeyAccess && isAnyKeyGenerated) ||
            (row.isGenerated === true && extendKeyAccess) ||
            (row.isGenerated === false && disableKeyAccess && isAnyKeyGenerated) ||
            (row.isGenerated === true && disableKeyAccess) ||
            (row.isGenerated === true && !isKeyDeleted) ||
            (row.isGenerated === true && enableKeyAccess) ||
            (row.isGenerated === false && enableKeyAccess) ||
            (row.isGenerated === false) ||
            (row.isGenerated === true) ||

            updateKeyAccess && isAnyKeyGenerated) ? (
            <DropdownButton
              id="dropdown-basic-button"
              title=""
              className="menu"
              disabled={
                !(row.isGenerated === false && editKeyAccess || isAnyKeyGenerated) &&
                !(row.isGenerated === true && extendKeyAccess) &&
                !(row.isGenerated === false && disableKeyAccess || isAnyKeyGenerated) &&
                !(row.isGenerated === true && disableKeyAccess) &&
                !(row.isGenerated === true && !isKeyDeleted) &&
                !(row.isGenerated === true && enableKeyAccess) &&
                !(row.isGenerated === false && enableKeyAccess) &&
                !(row.isGenerated === false) &&
                !(row.isGenerated === true) &&
                !updateKeyAccess && !isAnyKeyGenerated
              } >
              {row.isGenerated === false && editKeyAccess && !isAnyKeyGenerated && (
                <Dropdown.Item
                  onClick={() => actionEdit(row.uuid, row.keyGenId, row.customerName)}
                  className="edit"
                  disabled={!editKeyAccess} >
                  Edit
                </Dropdown.Item>
              )}

              {row.isGenerated === true && extendKeyAccess && !disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId) && (
                <Dropdown.Item
                  onClick={() => handleAction('extend', row.uuid, row.keyGenId)}
                  className="extend"
                  disabled={!extendKeyAccess}>
                  Extend
                </Dropdown.Item>
              )}
              {row.isGenerated === true && enableKeyAccess === true &&
                (disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId)) && (
                  <Dropdown.Item onClick={() => actionEnable(row.keyGenId, row.uuid)} className="enable" disabled={!enableKeyAccess}>
                    Enable
                  </Dropdown.Item>
                )}
              {row.isGenerated === false && disableKeyAccess && !isAnyKeyGenerated &&
                (!disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId)) && (
                  <Dropdown.Item onClick={() => actionDeActive('assign', row.uuid, row.keyGenId)} className="delete"
                    disabled={!disableKeyAccess}>
                    De-active
                  </Dropdown.Item>
                )}
              {row.isGenerated === true && disableKeyAccess &&
                (!disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId)) && (
                  <Dropdown.Item onClick={() => actionDeActive('generate', row.uuid, row.keyGenId)} className="delete"
                    disabled={!disableKeyAccess}>
                    De-active
                  </Dropdown.Item>
                )}

              {row.isGenerated === true && isKeyDeleted &&
                (disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId)) && (
                  <Dropdown.Item
                    onClick={() => handleDeleteConfirmation(row.keyGenId)}
                    className="delete"
                    disabled={!isKeyDeleted}
                  >
                    Delete
                  </Dropdown.Item>
                )}

              {row.isGenerated === true && updateKeyAccess && !isAnyKeyGenerated && (
                <Dropdown.Item onClick={() => editUUID(row.uuid, row.keyGenId)} className="primary"
                  disabled={!updateKeyAccess}>
                  Update UUID
                </Dropdown.Item>
              )}
              {/* {row.isGenerated === true  && (
               <Dropdown.Item
                onClick={() => actionHistory( row.keyGenId)}
                className="success"
              //disabled={!fetchEditSuccess}
              >
                View History
              </Dropdown.Item> 
              )}
               {row.isGenerated === false  && (
               <Dropdown.Item
                onClick={() => actionAssignHistory( row.keyGenId)}
                className="success"
              //disabled={!fetchEditSuccess}
              >
                Assign History
              </Dropdown.Item> 
              )} */}
              <Dropdown.Item
                onClick={() => {
                  if (row.isGenerated === true) {
                    actionHistory(row.keyGenId);
                  } else {
                    actionAssignHistory(row.keyGenId);
                  }
                }}
                className="success"
              // disabled={!fetchEditSuccess}
              >
                {row.isGenerated === true ? "View History" : "Assign History"}
              </Dropdown.Item>

            </DropdownButton>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id={`tooltip-${row.id}`}>
                  No available actions
                </Tooltip>
              }>
              <DropdownButton
                id="dropdown-basic-button"
                title=""
                className="menu"
              />
            </OverlayTrigger>
          )}
        </div>
      ),
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'

      },
    },
  };

  // Define a global array to store extended keyGenIds


  const UseExtendedStatus = (keyGenId, isActive, status) => {
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => {
      const checkExtend = async () => {
        try {
          if (keyGenId) {
            const response = await axios.get(CHECK_KEY_EXTEND + `${keyGenId}/${status}`);// have to change the url
            const { result } = response.data;
            //console.log("Extended ", response.data);

            if (result !== undefined) {
              setIsExtended(result.isExtended);
              //console.log("Extended? ", isExtended);
              setIsExtendedKey(true);

              //console.log("API Extend ", result.result.isExtended);
            } else {
              console.error('Invalid response format:', response.data);
              setIsExtended(false);
              setIsExtendedKey(false);
            }
          } else {
            setIsExtended(false);
            setIsExtendedKey(false);
          }
        } catch (error) {
          console.warn('These are disable keys');
          setIsExtended(false);
          setIsExtendedKey(false);
        }
      };
      checkExtend();
    }, [isActive, keyGenId]);
    return isExtended;
  };

  


  /*  const getStatusButton = (row, activeKeyGenIds, disabledKeyGenIds, expiredKeys, disabledKeyGenIdSeller, handleViewKey) => {
    const isActive = activeKeyGenIds.includes(row.keyGenId);
    const isExtended = UseExtendedStatus(row.keyGenId, isActive, 0);
    const isNewActiveKey = isActive && !disabledKeyGenIds.includes(row.keyGenId);
    const isExpired = Array.isArray(expiredKeys) && expiredKeys.map(key => key.keyGenId).includes(row.keyGenId);



    

    const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];

    return (
      <Button
        className={`success-button ${disabledKeyGenIds.includes(row.keyGenId) && disabledKeyIds.includes(row.keyGenId) || expiredKeyId.includes(row.keyGenId) ? 'danger' : ''}`}
        variant={
          disabledKeyIds.includes(row.keyGenId) || disabledKeyGenIds.includes(row.keyGenId) || new Date(row.validTo) < new Date()
            ? 'danger'

            : isExtended && !expiredKeyId.includes(row.keyGenId)
              ? 'primary'
              : 'success'
        }
        onClick={() => handleViewKey(row.uuid, row.keyGenId, row.customerName)}
        disabled={!row.isGenerated || disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId)}
      >
        {!disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId) && <FaEdit className="icon" />}
        {(row.isGenerated === true  || row.isGenerated === false) && !activeKeyGenIds.includes(row.keyGenId) && (disabledKeyIds.includes(row.keyGenId) || disabledKeyGenIds.includes(row.keyGenId)) ? 'Disabled' :

          (new Date(row.validTo) < new Date()  ? 'Expired' : (!isExtended && !expiredKeyId.includes(row.keyGenId) && row.isGenerated && activeKeyGenIds.includes(row.keyGenId)  ? 'Generate' : (isExtended && !expiredKeyId.includes(row.keyGenId) ? 'Extended' : 'Generate')))}
      </Button>
    );
  };

  */

 /*  const getStatusButton = (row, activeKeyGenIds, disabledKeyGenIds, expiredKeys, disabledKeyGenIdSeller, handleViewKey) => {
    const isActive = activeKeyGenIds.includes(row.keyGenId);
    const isExtended = UseExtendedStatus(row.keyGenId, isActive, 0);
    const isNewActiveKey = isActive && !disabledKeyGenIds.includes(row.keyGenId);
    
    // Determine if the key is perpetual
    const isPerpetual = row.keyType === 'PERPETUAL' && row.days === 0;
    
    // Determine if the key is expired, excluding perpetual keys
    const isExpired = !isPerpetual && Array.isArray(expiredKeys) && expiredKeys.map(key => key.keyGenId).includes(row.keyGenId);
    
    const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];
    
    return (
        <Button
            className={`success-button ${disabledKeyGenIds.includes(row.keyGenId) && disabledKeyIds.includes(row.keyGenId) || expiredKeyId.includes(row.keyGenId) ? 'danger' : ''}`}
            variant={
                disabledKeyIds.includes(row.keyGenId) || disabledKeyGenIds.includes(row.keyGenId) || (!isPerpetual && new Date(row.validTo) < new Date())
                    ? 'danger'
                    : isExtended && !expiredKeyId.includes(row.keyGenId)
                        ? 'primary'
                        : 'success'
            }
            onClick={() => handleViewKey(row.uuid, row.keyGenId, row.customerName)}
            disabled={!row.isGenerated || disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId)}
        >
            {!disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId) && <FaEdit className="icon" />}
            {(row.isGenerated === true || row.isGenerated === false) && !activeKeyGenIds.includes(row.keyGenId) && (disabledKeyIds.includes(row.keyGenId) || disabledKeyGenIds.includes(row.keyGenId)) ? 'Disabled' :
                (!isPerpetual && new Date(row.validTo) < new Date() ? 'Expired' : (!isExtended && !expiredKeyId.includes(row.keyGenId) && row.isGenerated && activeKeyGenIds.includes(row.keyGenId) ? 'Generate' : (isExtended && !expiredKeyId.includes(row.keyGenId) ? 'Extended' : 'Generate')))}
        </Button>
    );
};
 */

const getStatusButton = (
  row,
  activeKeyGenIds,
  disabledKeyGenIds,
  expiredKeys,
  disabledKeyGenIdSeller,
  handleViewKey
) => {
  const isActive = activeKeyGenIds.includes(row.keyGenId);
  const isExtended = UseExtendedStatus(row.keyGenId, isActive, 0);
  const isNewActiveKey = isActive && !disabledKeyGenIds.includes(row.keyGenId);

  // Determine if the key is perpetual
  const isPerpetual = row.keyType === 'PERPETUAL';

  // Determine if the key is expired, including perpetual keys with non-zero days
  const isPerpetualExpired = isPerpetual && row.days !== 0 && new Date(row.validTo) < new Date();
  
  // Determine if the key is expired, excluding perpetual keys
  const isExpired = !isPerpetual && Array.isArray(expiredKeys) && expiredKeys.map(key => key.keyGenId).includes(row.keyGenId);

  const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];

  return (
    <Button
      className={`success-button ${
        (disabledKeyGenIds.includes(row.keyGenId) && disabledKeyIds.includes(row.keyGenId)) ||
        expiredKeyId.includes(row.keyGenId)
          ? 'danger'
          : isPerpetualExpired
          ? 'orange' // Apply orange class for expired perpetual keys
          : ''
      }`}
      style={
        isPerpetualExpired
          ? { backgroundColor: '#F28C28', color: 'white' } // Directly apply the orange color
          : {}
      }
      variant={
        disabledKeyIds.includes(row.keyGenId) ||
        disabledKeyGenIds.includes(row.keyGenId) ||
        (!isPerpetual && new Date(row.validTo) < new Date())
          ? 'danger'
          : isPerpetualExpired
          ? 'warning' // Use 'warning' for variant or set a custom style
          : isExtended && !expiredKeyId.includes(row.keyGenId)
          ? 'primary'
          : 'success'
      }
      onClick={() => handleViewKey(row.uuid, row.keyGenId, row.customerName)}
      disabled={
        !row.isGenerated ||
        disabledKeyGenIds.includes(row.keyGenId) ||
        disabledKeyIds.includes(row.keyGenId)
      }
    >
      {!disabledKeyGenIds.includes(row.keyGenId) &&
        !disabledKeyIds.includes(row.keyGenId) && <FaEdit className="icon" />}
      {(row.isGenerated === true || row.isGenerated === false) &&
      !activeKeyGenIds.includes(row.keyGenId) &&
      (disabledKeyIds.includes(row.keyGenId) ||
        disabledKeyGenIds.includes(row.keyGenId))
        ? 'Disabled'
        : isPerpetualExpired
        ? 'Expired' // Keep status as 'Expired' for perpetual keys with orange color
        : !isPerpetual && new Date(row.validTo) < new Date()
        ? 'Expired'
        : !isExtended &&
          !expiredKeyId.includes(row.keyGenId) &&
          row.isGenerated &&
          activeKeyGenIds.includes(row.keyGenId)
        ? 'Generate'
        : isExtended && !expiredKeyId.includes(row.keyGenId)
        ? 'Extended'
        : 'Generate'}
    </Button>
  );
};



 /*  const getStatusButton = (row, activeKeyGenIds, disabledKeyGenIds, expiredKeys, disabledKeyGenIdSeller, handleViewKey) => {
    const isActive = activeKeyGenIds.includes(row.keyGenId);
    const isExtended = UseExtendedStatus(row.keyGenId, isActive, 0);
    const isNewActiveKey = isActive && !disabledKeyGenIds.includes(row.keyGenId);
    
    // Check if the key is expired
    const isExpired = Array.isArray(expiredKeys) && expiredKeys.map(key => key.keyGenId).includes(row.keyGenId);
    
    // Check if the key is 'PERPETUAL'
    const isPerpetual = row.keyType === 'PERPETUAL';
  
    // Determine the button class based on key status
    const buttonClass = `success-button ${(
      (disabledKeyGenIds.includes(row.keyGenId) && disabledKeyIds.includes(row.keyGenId) && !isPerpetual) || 
      (isExpired && !isPerpetual)
    ) ? 'danger' : ''}`;
  
    // Determine the button variant
    const buttonVariant = (
      disabledKeyIds.includes(row.keyGenId) || 
      disabledKeyGenIds.includes(row.keyGenId) || 
      (new Date(row.validTo) < new Date() && !isPerpetual) 
        ? 'danger'
        : isExtended && !isExpired
        ? 'primary'
        : 'success'
    );
  
    // Determine the button text
    const buttonText = isPerpetual 
      ? 'Perpetual' 
      : (
        (row.isGenerated === true || row.isGenerated === false) && 
        !activeKeyGenIds.includes(row.keyGenId) && 
        (disabledKeyIds.includes(row.keyGenId) || disabledKeyGenIds.includes(row.keyGenId))
          ? 'Disabled'
          : (new Date(row.validTo) < new Date() && !isPerpetual 
            ? 'Expired' 
            : (!isExtended && !isExpired && row.isGenerated && activeKeyGenIds.includes(row.keyGenId)
              ? 'Generate'
              : (isExtended && !isExpired ? 'Extended' : 'Generate'))
          )
      );
  
    // Inline style object
    const buttonStyle = {
      backgroundColor: isPerpetual ? '#9c27b0' : '',
      color: isPerpetual ? 'white' : '',
      borderColor: isPerpetual ? '#9c27b0' : '',
    };
  
    return (
      <Button
        className={buttonClass}
        variant={buttonVariant}
        onClick={() => handleViewKey(row.uuid, row.keyGenId, row.customerName, row.keyType)}
        disabled={
          !row.isGenerated || 
          disabledKeyGenIds.includes(row.keyGenId) || 
          disabledKeyIds.includes(row.keyGenId) 
           // Ensure 'PERPETUAL' keys are always enabled for their specific button
        }
        style={buttonStyle} // Apply inline styles here
      >
        {!disabledKeyGenIds.includes(row.keyGenId) && !disabledKeyIds.includes(row.keyGenId) && <FaEdit className="icon" />}
        {buttonText}
      </Button>
    );
  }; */
  

  const fetchKeys = async (val, type, isDelete, transferCustomerId) => {
    let result;



    try {
      const response = await axios.get(KEY_LIST + `/${val}/${type}/${isDelete}`);
      const status = response.data.status;
      result = response.data.result;
      const msg = response.data.msg;
      //console.log('response ',result )
      setKeyListPrint(result)
      if (status) {
        // Check if result is an array and not empty
        if (Array.isArray(result) && result.length > 0) {





          setKeyList(result);
          //console.log("Keys ", keyList);




        } else {
          setKeyList([]);

        }
        setIsLoading(false);
      } else {
        setKeyList([]);

        setIsLoading(false);
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      setIsLoading(false);
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        //console.log("Error define key ", err.response);
      }
    }

    // Always return the result, even if it's an empty array or undefined
    return result || [];

  };

  /*  const fetchData = async () => {
     try {
 
       const activeKeysResponse = await fetchKeys(val, '1'); // Active keys
       const disabledKeysResponse = await fetchKeys(val, '0'); // Disabled keys
 
       const combinedKeys = [...activeKeysResponse, ...disabledKeysResponse];
 
       // Update the disabledKeys state with the UUIDs of disabled keys
       const disabledUUIDs = disabledKeysResponse.map(key => key.uuid);
       //setDisabledKeys(disabledUUIDs);
       const disabledKeyGenIds = disabledKeysResponse.map(key => key.keyGenId);
       setDisabledKeyGenIds(disabledKeyGenIds);
       setDisabledKeys(disabledKeyGenIds);
 
 
       const activeUUIDs = activeKeysResponse.map(key => key.uuid);
       setActiveKeys(activeUUIDs);
       const activeKeyGenIds = activeKeysResponse.map(key => key.keyGenId);
       setActiveKeyGenIds(activeKeyGenIds);
 
       const currentDate = new Date();
         const formattedCurrentDate = currentDate.toISOString().split('T')[0];
 
         // Function to check if a key is expired
         
 
         
   
         const expiredKeys = combinedKeys.filter(row => formattedCurrentDate > row.validTo);
         const expiredKeyId = expiredKeys.map(key => key.keyGenId);
 
       if (disabledKeyGenIds) {
         setIsDisabled(true);
       } else {
         setIsDisabled(false);
       }
 
      /*  const filteredKeys = combinedKeys.filter(row =>
         !(
           row.isGenerated === false &&
           fetchAccessSuccess &&
           (disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId))
         ) 
       );
       setKeyList(filteredKeys);
       setKeyListPrint(filteredKeys);
       setExpiredKeys(expiredKeys);
       setExpiredKeyId(expiredKeyId)
       const isAnyKeyDisabled = disabledKeysResponse.length > 0;
       setIsKeyDisabled(isAnyKeyDisabled); 
 
       let filteredKeys = [];
 
       // Loop through each key and apply filtering logic
       for (const key of combinedKeys) {
         // Check if the key should be skipped based on your conditions
         if (
           key.isGenerated === false &&
           fetchAccessSuccess &&
           (disabledKeyGenIds.includes(key.keyGenId) || disabledKeyIds.includes(key.keyGenId))
         ) {
           continue;
         }
   
         // Add the key to the filteredKeys array
         filteredKeys.push(key);
         
         // Update the table with the filtered keys
         setKeyList(filteredKeys);
         setKeyListPrint(filteredKeys);
         setExpiredKeys(expiredKeys);
         setExpiredKeyId(expiredKeyId);
         const isAnyKeyDisabled = disabledKeysResponse.length > 0;
         setIsKeyDisabled(isAnyKeyDisabled);
         
         // Wait for some time before fetching the next key (optional)
         await new Promise(resolve => setTimeout(resolve, 100)); // Adjust the delay as needed
       }
       
     } catch (err) {
       console.error("Cannot generate data", err); // Handle any errors here
     }
   };
  */

  const fetchData = async () => {

    try {
      const activeKeysResponse = await fetchKeys(val, '1', '0'); // Active keys
      const disabledKeysResponse = await fetchKeys(val, '0', '0'); // Disabled keys

      const combinedKeys = [...activeKeysResponse, ...disabledKeysResponse];

      // Update the disabledKeys state with the UUIDs of disabled keys
      const disabledKeyGenIds = disabledKeysResponse.map(key => key.keyGenId);
      setDisabledKeyGenIds(disabledKeyGenIds);
      setDisabledKeys(disabledKeyGenIds);

      const activeUUIDs = activeKeysResponse.map(key => key.uuid);
      setActiveKeys(activeUUIDs);
      const activeKeyGenIds = activeKeysResponse.map(key => key.keyGenId);
      setActiveKeyGenIds(activeKeyGenIds);

      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().split('T')[0];

      // Function to check if a key is expired
      const expiredKeys = combinedKeys.filter(row => formattedCurrentDate > row.validTo);
      const expiredKeyId = expiredKeys.map(key => key.keyGenId);

      if (disabledKeyGenIds) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      const filteredKeysPrint = combinedKeys.filter(row =>
        !(
          row.isGenerated === false &&
          fetchAccessSuccess &&
          (disabledKeyGenIds.includes(row.keyGenId) || disabledKeyIds.includes(row.keyGenId))
        )
      );

      setKeyListPrint(filteredKeysPrint);

      // Initialize arrays to collect keys
      let filteredKeys = [];
      //let printedKeys = [];
      let expiredKeysList = [];
      let expiredKeyIdsList = [];
      let isAnyKeyDisabled = false;

      // Recursive function to fetch keys one by one
      const fetchKeySequentially = async (keys, index) => {
        if (index >= keys.length) return; // Exit condition

        const key = keys[index];

        // Check if the key should be skipped based on your conditions
        if (
          key.isGenerated === false &&
          fetchAccessSuccess &&
          (disabledKeyGenIds.includes(key.keyGenId) || disabledKeyIds.includes(key.keyGenId))
        ) {
          // Skip this key and fetch the next one
          fetchKeySequentially(keys, index + 1);
          return;
        }

        // Add the key to the filteredKeys array
        filteredKeys.push(key);
        //printedKeys.push(key);

        if (formattedCurrentDate > key.validTo) {
          expiredKeysList.push(key);
          expiredKeyIdsList.push(key.keyGenId);
        }

        isAnyKeyDisabled = disabledKeysResponse.length > 0;

        // Update the table with the filtered keys for each iteration
        setKeyList([...filteredKeys]);
        //setKeyListPrint([...printedKeys]);
        setExpiredKeys([...expiredKeysList]);
        setExpiredKeyId([...expiredKeyIdsList]);
        setIsKeyDisabled(isAnyKeyDisabled);

        /*  // Wait for some time before fetching the next key (optional)
         await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed */

        // Fetch the next key recursively
        fetchKeySequentially(keys, index + 1);
      };

      // Start fetching keys sequentially
      fetchKeySequentially(combinedKeys, 0);

    } catch (err) {
      console.error("Cannot generate data", err); // Handle any errors here
    }
  };

//console.log("Active keys ", activeKeyGenIds)
//console.log("Disabled keys ", disabledKeyGenIds)
  

  useEffect(() => {
    if (refreshTable) {
      fetchData(val);
      //fetchKeys(val, 0);
      setRefreshTable(false);

    }
  }, [refreshTable, keyListPrint, val]);



  useEffect(() => {
    const fetchDisabledKeys = async () => {
      try {
        const disabledKeysResponse = await fetchKeys(val, '0', '0'); // Fetch disabled keys
        setDisabledFilterKeys(disabledKeysResponse); // Update disabled keys state
       
      } catch (error) {
        console.error('Error fetching disabled keys:', error);
        setDisabledFilterKeys([]); // Reset disabled keys state on error
      }
    };
  
    fetchDisabledKeys(); // Fetch disabled keys initially
  
    // No need to call fetchSearchData directly here, we will manage it through dependencies
  
  }, [val]);


 
  /*   useEffect(() => {
      if (refreshTable) {
        fetchKeys(val, 1);
        fetchKeys(val, 0);
        setRefreshTable(false);
        // Reset the state after refreshing
      }
    }, [refreshTable, val]);
   */
  useEffect(() => {

    loadData();
    setSellerSearch('');
    setKeyList('');
    setCustomerSearch('');
  }, [val]);





  const fetchExtendedStatus = async (keyGenId) => {
    try {
      const response = await axios.get(CHECK_KEY_EXTEND + `${keyGenId}/0`);
      const { result } = response.data;
      //console.log("Results", response)
      // Check if the data has the expected structure
      if (result !== undefined) {


        return result.isExtended;
      } else {
        console.warn('Invalid response format:', response.data);
        return false;
      }
    } catch (error) {
      console.warn('Error while checking extended status');
      return false;
    }
  };



  const fetchSellers = async (sellerId, value, isDelete) => {
    try {
      //sellerId = 1;
      const response = await axios.get(SELLER_LIST + `${sellerId}/${value}/${isDelete}`);
      //console.log('API Response:', response.data);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if (status) {
        if (result) {
          return result; // Return the result if it exists
        } else {
          return []; // Return an empty array if result is not present
        }
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
        return []; // Return an empty array in case of error
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      return []; // Return an empty array in case of error
    }
  };

  const fetchAllSellerKeys = async (sellerId) => {
    try {
      //sellerId = 1;
      const activeKeys = await fetchSellers(sellerId, '1', 0); // Active keys
      const disabledKeys = await fetchSellers(sellerId, '0', 0); // Disabled keys value
      const combinedKeys = [...activeKeys, ...disabledKeys];
      setActiveKeyGenIdSeller(activeKeys);
      setDisabledKeyGenIdSeller(disabledKeys);
      const activeKeyIds = Array.isArray(activeKeys) ? activeKeys.map((key) => key.keyGenId) : [];
      setActiveKeyGenIds(activeKeyIds);
      const disabledKeyIds = Array.isArray(disabledKeyGenIdSeller) ? disabledKeyGenIdSeller.map((key) => key.keyGenId) : [];
      setDisabledFilterKeys(disabledKeys);

      const hasActiveSellers = activeKeys.length > 0 || disabledKeys.length > 0;
      setAreSellersAvailable(hasActiveSellers);

      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().split('T')[0];
      const expiredKeys = combinedKeys.filter(row => formattedCurrentDate > row.validTo);
      const expiredKeyIds = expiredKeys.map(key => key.keyGenId);

      const keysWithExtendedStatus = await Promise.all(
        combinedKeys.map(async (key) => {
          const isExtended = await fetchExtendedStatus(key.keyGenId);
          return { ...key, isExtended, isExtendedKey };
        })
      );
      
      setKeyList(keysWithExtendedStatus);
      setSellerKeys(keysWithExtendedStatus);
      setExpiredKeys(expiredKeys);
      setExpiredKeyId(expiredKeyIds);
      const isAnyKeyDisabled = disabledKeys.length > 0;
      setIsKeyDisabled(isAnyKeyDisabled);
    } catch (err) {
      console.error('Cannot generate data', err); // Handle any errors here
    }
  };

  //console.log('', sellerKeys)

  const fetchCustomerKeys = async (customerId, keyVal, isDelete) => {
    try {
      const response = await axios.get(CUSTOMER_KEY + `${customerId}/${keyVal}/${isDelete}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      //console.log("Response ", response);

      if (status) {
        if (result) {
          return result; // Return the keyList if it exists
        } else {
          return []; // Return an empty array if keyList is not present
        }
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
        return []; // Return an empty array in case of error
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response.status !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      return []; // Return an empty array in case of error
    }
  };

  /* const fetchAllCustomerKeys = async (customerId) => {
    try {
      const activeKeys = await fetchCustomerKeys(customerId, '1', 0); // Active keys
      const disabledKeys = await fetchCustomerKeys(customerId, '0', 0); // Disabled keys
      const combinedKeys = [...activeKeys, ...disabledKeys];

      // Log information for debugging
      //console.log('Active Keys:', activeKeys);
      //console.log('Disabled Keys:', disabledKeys);
      //console.log('Combined Keys:', combinedKeys);
      //console.log('Keys with Extended Status:', keysWithExtendedStatus);

      const isAnyKeyGenerated = combinedKeys.some((key) => !key.isGenerated);

      // Use isAnyKeyGenerated as needed
      //console.log('Is any key generated:', isAnyKeyGenerated);


      setActiveKeyGenIdCustomer(activeKeys);
      const activeKeyCustIds = Array.isArray(activeKeyGenIdCustomer) ? activeKeyGenIdCustomer.map((key) => key.keyGenId) : [];
      setActiveKeyGenIds(activeKeyCustIds);
      setDisabledKeyGenIdCustomer(disabledKeys);
      const disableKeyCustIds = Array.isArray(disabledKeyGenIdCustomer) ? disabledKeyGenIdCustomer.map((key) => key.keyGenId) : [];
      setDisabledKeyGenIds(disableKeyCustIds);
      setIsAnyKeyGenerated(isAnyKeyGenerated)

      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().split('T')[0];
      const expiredKeys = combinedKeys.filter(row => formattedCurrentDate > row.validTo);
      const expiredKeyIds = expiredKeys.map(key => key.keyGenId);

      // Fetch extended status for each key
      const keysWithExtendedStatus = await Promise.all(
        combinedKeys.map(async (key) => {
          const isExtended = await fetchExtendedStatus(key.keyGenId);
          return { ...key, isExtended };
        })
      );


      // Check if any key is disabled
      setKeyList(keysWithExtendedStatus);
      setExpiredKeys(expiredKeys);
      setExpiredKeyId(expiredKeyIds);
      const isAnyKeyDisabled = disabledKeys.length > 0;
      setIsKeyDisabled(isAnyKeyDisabled);
    } catch (err) {
      console.error('Cannot fetch customer keys', err); // Handle any errors here
    }
  };
 */
  const fetchAllCustomerKeys = async (customerId) => {
    try {
      const activeKeys = await fetchCustomerKeys(customerId, '1', 0); // Active keys
      const disabledKeys = await fetchCustomerKeys(customerId, '0', 0); // Disabled keys
      const combinedKeys = [...activeKeys, ...disabledKeys];

      const hasCustomerKeys = activeKeys.length > 0 || disabledKeys.length > 0;
      setAreCustomerAvailable(hasCustomerKeys); 

      const activeKeyCustIds = activeKeys.map((key) => key.keyGenId);
      const disableKeyCustIds = disabledKeys.map((key) => key.keyGenId);

      // Use isAnyKeyGenerated as needed
      const isAnyKeyGenerated = combinedKeys.some((key) => !key.isGenerated);

      setActiveKeyGenIdCustomer(activeKeys);
      setActiveKeyGenIds(activeKeyCustIds);

      setDisabledKeyGenIdCustomer(disabledKeys);
      setDisabledFilterKeys(disabledKeys);

      setIsAnyKeyGenerated(isAnyKeyGenerated);

      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().split('T')[0];
      const expiredKeys = combinedKeys.filter((row) => formattedCurrentDate > row.validTo);
      const expiredKeyIds = expiredKeys.map((key) => key.keyGenId);

      // Fetch extended status for each key
      const keysWithExtendedStatus = await Promise.all(
        combinedKeys.map(async (key) => {
          const isExtended = await fetchExtendedStatus(key.keyGenId);
          return { ...key, isExtended };
        })
      );

      // Update state with the keys and their extended status
      setKeyList(keysWithExtendedStatus);
      setCustomerKeys(keysWithExtendedStatus);
      setExpiredKeys(expiredKeys);
      setExpiredKeyId(expiredKeyIds);

      const isAnyKeyDisabled = disabledKeys.length > 0;
      setIsKeyDisabled(isAnyKeyDisabled);
    } catch (err) {
      console.error('Cannot fetch customer keys', err); // Handle any errors here
    }
  };


  
  //console.log("DeActive KeyGen", disabledKeyGenIdSeller.includes(row.keyGenId));
  useEffect(() => {
    setActionView(0)
  }, [setActionView])



  useEffect(() => {
    setFilteredItems(uuids.map((uuid) => ({ uuid })));
    setUuidEdit('');
  }, [uuids]);

  //action buttons
  const handleViewKey = (uuid, keyGenId, name, type) => {
    setUuidEdit(uuid)
    setOpenKeyPopup(true)//Generate popup - "your key can download or copy to clipboard"
    setName(name)
    setKeyGenId(keyGenId)
    setLicenseType(type)
  }
  //action buttons
  const handleAction = (action, uuid, keyGenId) => {
    setUuidEdit(uuid)
    setKeyGenId(keyGenId)
    if (action === 'extend') {
      setOpenExtend(true)
      localStorage.setItem('lastActiveTab', '2');
    }
  }

  const actionEnable = (keyGenId, uuid) => {
    setUuidEdit(uuid);
    setKeyGenId(keyGenId);
    // First, check if there are any active keys for the UUID
    //setUuidEdit(uuid);
    axios
      .get(CHECK_UUIDS + `${uuid}`)
      .then((response) => {
        const keyDetails = response.data;
        if (keyDetails && keyDetails.status) {
          // There are active keys for this UUID
          setOpenPopup(true);
          setMessage("There are already active keys for this UUID.");
          setServerity("error");
          setOpen(true);
        } else {
          // No active keys for this UUID, proceed to enable the key
          setOpenEnable(true);
          if (openEnable === true) {
            axios
              .get(ENABLE_KEY + `${keyGenId}`)  //http://103.6.169.50:8080/api/key/enableGenaratedKey
              .then((enableResponse) => {
                //console.log("Enable Response:", enableResponse); // Log the response
                if (enableResponse.status === 200) {
                  // Handle successful enabling of the key
                  setMessage("Key enabled successfully.");
                  setServerity("success");
                  setOpenEnable(false);
                  fetchData(val, 1, 0);
                  fetchData(val, 0, 0);
                  setUuidEdit('');
                  // Optionally, you can update the UI or perform other actions
                  // after the key is enabled.
                } else {
                  console.error("Error enabling the key: Invalid response status");
                  setMessage("Error enabling the key. Please try again.");
                  setServerity("error");
                  setOpen(true);
                }
              })
              .catch((enableError) => {
                console.error("Error enabling the key:", enableError);
                setMessage("Error enabling the key. Please try again.");
                setServerity("error");
                setOpenEnable(false);
              });
            //console.log("Key Generate Id ", keyGenId);
          }
        }
        //setUuidEdit("");
      })
      .catch((error) => {
        console.error("Error fetching key details:", error);
      });
     
  };



  const handleDeleteConfirmation = (keyGenId) => {
    setOpenDelete(true);
    // Set the keyGenId to be used when "Yes" is clicked
    setKeyGenId(keyGenId);
  };

  const actionDelete = async (keyGenId) => {
    setOpenDelete(false);
    const delStatus = true;
    try {
      const response = await axios.put(`${KEY_DELETE}${keyGenId}/${delStatus}`);
      if (response.status === 200) {
        const data = response.data;
        const msg = response.data.msg;
        if (data.status) {
          //console.log('Key deleted successfully', data.msg);
          await fetchData(val);
          variant = 'success';
          enqueueSnackbar('Key Deleted Successfully!', { variant });
        } else {
          console.error('Failed to delete key:', data.msg);
          variant = 'error';
          enqueueSnackbar('Failed to delete key', { variant });
        }
      } else {
        console.error('Failed to delete key: Unexpected server response');
        variant = 'error';
        enqueueSnackbar('Failed to delete key: Unexpected server response', { variant });
      }
    } catch (error) {
      console.error('Error while deleting key', error.message);
      variant = 'error';
      enqueueSnackbar('Error while deleting key', { variant });
    }
  };


  const actionDeActive = (status, uuid, keyGenId) => {
    setUuidEdit(uuid);
    setKeyGenId(keyGenId);
    const updatedKeyList = keyList.map((key) => {
      if (key.uuid === uuid) {
        key.isDisabled = status === 'assign' || status === 'generate';
      }
      return key;
    });
    setKeyList(updatedKeyList);
    if (status === 'assign') {
      setOpenDeActiveA(true);
      fetchData(val, 1, 0);
      fetchData(val, 0, 0);
    } else if (status === 'generate') {
      setOpenDeActiveG(true);
      fetchData(val, 1, 0);
      fetchData(val, 0, 0);
    }
  };

  //de-active the key
  const actionEdit = (uuid, keyGenId, name) => {
    setUuidEdit(uuid);
    setOpenEdit(true);
    setKeyGenId(keyGenId);
    setName(name)
    localStorage.setItem('lastActiveTab', '1');
  }

  //Open Edit UUID Button
  const editUUID = (uuid, keyId) => {
    setUuidEdit(uuid);
    setKeyGenId(keyId);
    setOpenEditUUID(true);
  }

  const actionHistory = (keyGenId) => {
    // First, fetch the key history for the given keyGenId
    axios
      .get(KEY_HISTORY + `${keyGenId}`)
      .then((response) => {
        // Handle the response data here
        //console.log("Key History:", response.data);
        setKeyGenId(keyGenId)
        setOpenKeyHistoryPopup(true);
        //console.log("Id ", keyGenId)
      })
      .catch((error) => {
        console.error("Error fetching key history:", error);
        // Handle errors if any
      });
  };


  const actionAssignHistory = (keyGenId) => {
    // First, fetch the key history for the given keyGenId
    axios
      .get(ASSIGN_KEY_HISTORY + `${keyGenId}`)
      .then((response) => {
        // Handle the response data here
        //console.log("Key History:", response.data);
        setKeyGenId(keyGenId)
        setOpenAssignHistoryPopup(true);
        //console.log("Id ", keyGenId)
      })
      .catch((error) => {
        console.error("Error fetching key history:", error);
        // Handle errors if any
      });
  };

  //de-active assign key
  const handleDeActiveA = async (e) => {
    setOpenDeActiveA(false)
    const newData = { uuid: uuidEdit }
    try {
      const response = await axios.post(ASSIGN_DE_ACTIVE, newData);
      setUuidEdit('')
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        await fetchData(val);

        variant = 'success'
        enqueueSnackbar('Key De-activated Successfully!', { variant })
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  //de-active generated key
  const handleDeActiveG = async (e) => {
    setOpenDeActiveG(false)
    const newData = { uuid: uuidEdit }
    try {
      const response = await axios.post(GENERATE_DE_ACTIVE, newData);
      setUuidEdit('')
      const status = response.data.status;
      const msg = response.data.msg;
      if (status) {
        fetchData(val);
        variant = 'success'
        enqueueSnackbar('Key De-activated Successfully!', { variant })
      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }



  const checkKey = async (uuids) => {
    try {

      const responses = await Promise.all(
        uuids.map(async (uuid) => {
          const response = await axios.get(CHECK_UUIDS + `${uuid}`);
          const { result, status, msg } = response.data;

          // Check if the response indicates success and has the expected structure
          if (result && result.keyGenId) {
            const keyGenId = result.keyGenId;

            // Check if the key is extended using the fetchExtendedStatus function
            const isExtendedKey = await fetchExtendedStatus(keyGenId);


            return { keyGenId, isExtendedKey };
          } else {
            console.warn('Invalid response format for UUID:', response.data);
            return null; // Return null if the response format is not as expected
          }
        })
      );

      const validKeyGenIds = responses.filter(Boolean); // Filter out null values (invalid responses)
      const disableKeyGenIds = responses.filter(Boolean);

      // Check if any valid keyGenIds were collected
      if (validKeyGenIds.length > 0) {
        // Check if the keyGenIds are extended using the fetchExtendedStatus function
        const extendedStatus = await Promise.all(
          validKeyGenIds.map(async (keyInfo) => {
            const isExtended = await fetchExtendedStatus(keyInfo.keyGenId);
            const isExtendedKey = isExtended ? '1' : '0';
            //console.log("mjhj", isExtended);

            return { ...keyInfo, isExtendedKey };
          })
        );


        return extendedStatus;
      }


      else {
        console.warn('No valid keyGenIds found for the given UUIDs.');
        return null;
      }

    } catch (error) {
      console.error('Error while checking keys:', error);
      return null; // Return null in case of an error
    }
  };

  //edit assigned key
  const handleEdit = () => {
    setValue(1)
  }

  //extend generated key
  const handleExtend = () => {
    setValue(2)
  }
  //const KeyData = useMemo(() => [...keyList], [keyList]);
  const KeyData = useMemo(() => {
    if (!Array.isArray(keyList)) {
      console.warn('keyList is not an array:', keyList);
      return [];
    }

    const uniqueKeyGenIds = new Set();
    return keyList.filter(item => {
      if (typeof item === 'object' && item !== null && 'keyGenId' in item) {
        if (!uniqueKeyGenIds.has(item.keyGenId)) {
          uniqueKeyGenIds.add(item.keyGenId);
          return true;
        }
      } else {
        console.error('Invalid item in keyList:', item);
      }
      return false;
    });
  }, [keyList]);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  /* useEffect(() => {



    const fetchSearchData = async () => {
      try {



        // Effect for handling search
        if (searchBy === 'uuid') {
          setFilteredItems(KeyData.filter(item =>
            item.uuid && item.uuid.toLowerCase().includes(filterText.toLowerCase()),
          ));
        } else if (searchBy === 'customerName') {
          setFilteredItems(KeyData.filter(item =>
            item.customerName && item.customerName.toLowerCase().includes(filterText.toLowerCase()),
          ));
        } else if (searchBy === 'months') {
          if (filterText !== '') {
            const filterNumber = parseInt(filterText);
            setFilteredItems(KeyData.filter(item => (item.months) === filterNumber));
          } else {
            setFilteredItems(KeyData);
          }
        } else if (searchBy === 'days') {
          if (filterText !== '') {
            const filterNumber = parseInt(filterText);
            setFilteredItems(KeyData.filter(item => (item.days) === filterNumber));
          } else {
            setFilteredItems(KeyData);
          }
        } else if (searchBy === 'userCount') {
          if (filterText !== '') {
            const filterNumber = parseInt(filterText);
            setFilteredItems(KeyData.filter(item => (item.userCount) === filterNumber));
          } else {
            setFilteredItems(KeyData);
          }
        }
        else if (searchBy === 'sellerName') {
          setFilteredItems(KeyData.filter(item =>
            item.sellerName && item.sellerName.toLowerCase().includes(filterText.toLowerCase()),
          ));
        } else {
          setFilteredItems(KeyData);

        }

        const uniqueActiveKeyGenIds = Array.from(new Set(activeKeyGenIds));

        //console.log("Assign ", KeyData.map(item => item.keyGenId));
        // Effect for handling keyType
        if (keyType === 'Permanent' || keyType === 'Temporary') {
          setFilteredItems(KeyData.filter(item =>
            item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase()) &&
            (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
          ));

        }



        if (keyIndex === 'true' && (keyType === 'Permanent' || keyType === 'Temporary')) {
          setFilteredItems(KeyData.filter(item =>
            item.isGenerated === true && uniqueActiveKeyGenIds.includes(item.keyGenId) &&
            item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase()) &&
            (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
          ));
        } else if (keyIndex === 'false' && (keyType === 'Permanent' || keyType === 'Temporary')) {
          setFilteredItems(KeyData.filter(item =>
            item.isGenerated === false && uniqueActiveKeyGenIds.includes(item.keyGenId) &&
            item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase()) &&
            (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
          ));
        } else if (keyIndex === 'true') {
          setFilteredItems(KeyData.filter(item =>
            item.isGenerated === true && uniqueActiveKeyGenIds.includes(item.keyGenId) &&
            (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
          ));
        }
        else if (keyIndex === 'false') {
          setFilteredItems(KeyData.filter(item =>
            item.isGenerated === false && uniqueActiveKeyGenIds.includes(item.keyGenId) &&
            (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
          ));
        } else {
          console.warn('No valid keyGenId found for UUID:');
        }



        // Effect for handling disabled keys
        if (keyIndex === '0') {
          const disabledKeys = await fetchKeys(val, '0', '0');

          if (Array.isArray(disabledKeys)) {
            //console.log('Disabled keys from the server:', disabledKeys);


            // Apply additional filtering based on other criteria
            const filteredDisabledKeys = disabledKeys.map(item => ({
              ...item,
              matchesDisabledKeys: true,
              matchesKeyType: !keyType || (item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase())),
              matchesKeyIndex: !keyIndex || (item.isGenerated === (keyIndex === 'true') || (keyIndex === '1' && item.isExtendedKey === '1')),
              matchesIsGenerated: !keyType || item.isGenerated === (keyType === 'true'),
            }));

            const uniqueSet = new Set(filteredDisabledKeys);

            // Convert the Set back to an array
            const uniqueFilteredDisabledKeys = Array.from(uniqueSet);

            if (keyType === 'Permanent' || keyType === 'Temporary') {
              const typeFilteredKeys = uniqueFilteredDisabledKeys.filter(item =>
                item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase()) &&
                (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
              );
              //console.log('Filtered by keyType:', typeFilteredKeys);
              setFilteredItems(typeFilteredKeys);
            } else if (searchBy !== '') {
              const searchFilteredKeys = uniqueFilteredDisabledKeys.filter(item =>
                searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true
              );
              //console.log('Filtered by searchBy:', searchFilteredKeys);
              setFilteredItems(searchFilteredKeys);
            }

            else {
              setFilteredItems(uniqueFilteredDisabledKeys);
            }

          } else {
            console.error('Invalid data fetched for disabled keys:', disabledKeys);
            setFilteredItems([]); // Ensure an empty array is set if there's an error
          }
        }

        // Effect for handling extended data
        if (keyIndex === '1' && KeyData.length > 0) {
          const keyGenIdsWithExtendedStatusArray = await checkKey(KeyData.map(item => item.uuid));

          //console.log('KeyData:', KeyData);
          //console.log('keyGenIdsWithExtendedStatusArray:', keyGenIdsWithExtendedStatusArray);

          if (Array.isArray(keyGenIdsWithExtendedStatusArray)) {
            // Map keyGenIdsWithExtendedStatusArray to include uuid property
            const uuidMap = keyGenIdsWithExtendedStatusArray.reduce((acc, item) => {
              if (item.uuid) {
                acc[item.keyGenId] = item.uuid;
              }
              return acc;
            }, {});

            // Include uuid property in filteredDataExtended
            const filteredDataExtended = keyGenIdsWithExtendedStatusArray
              .filter(item => item.isExtendedKey === '1')
              .map(item => {
                const keyDetails = KeyData.find(dataItem => dataItem.keyGenId === item.keyGenId);

                if (keyDetails) {
                  let matchesKeyIndex = false;

                  if (keyIndex === '1') {
                    matchesKeyIndex = item.isExtendedKey === '1';
                  }


                  return {
                    ...item,
                    uuid: keyDetails.uuid,
                    createdDate: keyDetails.createdDate,
                    cuId: keyDetails.cuId,
                    customerName: keyDetails.customerName,
                    days: keyDetails.days,
                    description: keyDetails.description,
                    isGenerated: keyDetails.isGenerated,
                    keyGenId: keyDetails.keyGenId,
                    keyType: keyDetails.keyType,
                    sellerId: keyDetails.sellerId,
                    sellerName: keyDetails.sellerName,
                    userCount: keyDetails.userCount,
                    validFrom: keyDetails.validFrom,
                    validTo: keyDetails.validTo,
                    matchesKeyIndex,
                    // Include the matchesKeyIndex property
                    // Add more properties as needed
                  };
                } else {
                  console.warn('Key details not found for keyGenId:', item.keyGenId);
                  return null;
                }
              })
              .filter(Boolean); // Remove null values if any

            const uniqueFilteredDataExtended = Array.from(
              new Map(filteredDataExtended.map(item => [item.keyGenId, item])).values()
            );

            //console.log('uniqueFilteredDataExtended:', uniqueFilteredDataExtended);
            //console.log('filteredDataExtended:', filteredDataExtended);

            // Set the final filtered items
            if (keyType === 'Permanent' || keyType === 'Temporary') {
              const typeFilterKeys = uniqueFilteredDataExtended.filter(item =>
                item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase()) &&
                (searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true)
              );
              //console.log('Filtered by keyType:', typeFilterKeys);
              setFilteredItems(typeFilterKeys);
            }
            else if (searchBy !== '') {
              const searchFilterKeys = uniqueFilteredDataExtended.filter(item =>
                searchBy ? (item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())) : true
              );
              //console.log('Filtered by searchBy:', searchFilterKeys);
              setFilteredItems(searchFilterKeys);
            }

            else {
              setFilteredItems(uniqueFilteredDataExtended);
            }


          } else {
            console.error('Invalid data fetched for extended keys:', keyGenIdsWithExtendedStatusArray);
            setFilteredItems([]); // Ensure an empty array is set if there's an error
          }
        }
      } catch (error) {
        console.error('Error during filtering:', error);
      }
    };

    fetchSearchData();
  }, [searchBy, filterText, KeyData, keyType, keyIndex, disabledKeys, val, activeKeyGenIds, disabledKeyGenIds]);

 */

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        let filteredItems = [...KeyData];
  
        // Function to filter items based on searchBy
        const filterBySearch = (items, searchBy, filterText) => {
          if (!filterText) return items;
          return items.filter(item =>
            item[searchBy] && item[searchBy].toLowerCase().includes(filterText.toLowerCase())
          );
        };
  
        // Function to filter items based on number fields (months, days, userCount)
        const filterByNumberField = (items, field, filterText) => {
          if (!filterText) return items;
          const filterNumber = parseInt(filterText);
          return items.filter(item => item[field] === filterNumber);
        };
  
        // Apply search filters
        if (searchBy === 'uuid' || searchBy === 'customerName' || searchBy === 'sellerName') {
          filteredItems = filterBySearch(filteredItems, searchBy, filterText);
        } else if (searchBy === 'months' || searchBy === 'days' || searchBy === 'userCount') {
          filteredItems = filterByNumberField(filteredItems, searchBy, filterText);
        }
  
        // Unique active key IDs
        const uniqueActiveKeyGenIds = Array.from(new Set(activeKeyGenIds));
  
        // Apply keyType filter
        if (keyType === 'Permanent' || keyType === 'Temporary' || keyType === 'Perpetual') {
          filteredItems = filteredItems.filter(item =>
            item.keyType && item.keyType.toLowerCase().includes(keyType.toLowerCase())
          );
        }
  
        // Apply keyIndex filter
        if (keyIndex === 'true') {
          filteredItems = filteredItems.filter(item =>
            item.isGenerated === true && uniqueActiveKeyGenIds.includes(item.keyGenId)
          );
        } else if (keyIndex === 'false') {
          filteredItems = filteredItems.filter(item =>
            item.isGenerated === false && uniqueActiveKeyGenIds.includes(item.keyGenId)
          );
        }
  
        // Handle disabled keys
      /* if (keyIndex === '0' && disabledFilterKeys.length > 0) {
        const uniqueDisabledKeyGenIds = new Set(disabledFilterKeys.map(item => item.keyGenId));
        filteredItems = filteredItems.filter(item =>
          uniqueDisabledKeyGenIds.has(item.keyGenId) && !uniqueActiveKeyGenIds.includes(item.keyGenId)
        );
      } */
      if (keyIndex === '0') {
        if (disabledFilterKeys.length === 0) {
          // If there are no disabled keys, set filteredItems to an empty array
          filteredItems = [];
        } else {
          const uniqueDisabledKeyGenIds = new Set(disabledFilterKeys.map(item => item.keyGenId));
          filteredItems = filteredItems.filter(item =>
            uniqueDisabledKeyGenIds.has(item.keyGenId) && !uniqueActiveKeyGenIds.includes(item.keyGenId)
          );
        }
      }

  
        // Handle extended keys
        if (keyIndex === '1' && KeyData.length > 0) {
          const keyGenIdsWithExtendedStatusArray = await checkKey(KeyData.map(item => item.uuid));
          if (Array.isArray(keyGenIdsWithExtendedStatusArray)) {
            const extendedKeyGenIds = keyGenIdsWithExtendedStatusArray
              .filter(item => item.isExtendedKey === '1')
              .map(item => item.keyGenId);
            filteredItems = filteredItems.filter(item => 
              extendedKeyGenIds.includes(item.keyGenId) && item.isGenerated !== false
            );
          } else {
            console.error('Invalid data fetched for extended keys:', keyGenIdsWithExtendedStatusArray);
            filteredItems = [];
          }
        }
  
        setFilteredItems(filteredItems);
        //console.log('KeyIndex', keyIndex);
        //console.log('Searched Data', filteredItems);
      } catch (error) {
        console.error('Error during filtering:', error);
      }
    };
  
    fetchSearchData();
  }, [searchBy, filterText, KeyData, keyType, keyIndex, val]);
  
  

  useEffect(() => {
    if (uuidSearch !== '' && actionView === 3) {
      setSearchBy('uuid')
      setFilterText(uuidSearch)
      loadData();
    }
  }, [uuidSearch]) //removed actionView

  /* function loadData() {
    if (sellerSearch !== '') { // nameSearch = sellerID
      fetchAllSellerKeys(sellerSearch);

    } else if (customerSearch !== '') {
      fetchAllCustomerKeys(customerSearch);

    } else {
      fetchData(val);
      //fetchData(val);
    }
  }
 */

  function loadData() {
    // Ensure sellerSearch is a string or number
    let sellerId = '';
    if (sellerSearch && typeof sellerSearch === 'object') {
      sellerId = sellerSearch.id || '';
    } else {
      sellerId = sellerSearch;
    }
  
    // Ensure customerSearch is a string or number
    let customerId = '';
    if (customerSearch && typeof customerSearch === 'object') {
      customerId = customerSearch.id || '';
    } else {
      customerId = customerSearch;
    }
  
    // Determine which data-fetching function to call
    if (sellerId !== '') { // Use sellerId for sellerSearch
      fetchAllSellerKeys(sellerId);
  
    } else if (customerId !== '') { // Use customerId for customerSearch
      fetchAllCustomerKeys(customerId);
  
    } else {
      fetchData(val);
    }
  }
  
  
  useEffect(() => {
    if (searchBy !== 'uuid' || filterText === '') {
      setUuidSearch('')
    }
  }, [searchBy, filterText]) // removed setUuidSearch

  useEffect(() => {

    if (sellerSearch !== '' && actionView === 3) {
      loadData();
    }
  }, [sellerSearch]) //removed actionView userType 

  useEffect(() => {

    if (customerSearch !== '' && actionView === 3) {
      loadData();
    }
  }, [customerSearch])


  useEffect(() => {

    if (userName !== '' && actionView === 3) {
      //setSearchBy('uuid')
      // fetchSellers(nameSearch);
      loadData();
      //console.log('namesearch : ' + userName)
      //fetchKeys(userSearch);
      // setVal(userSearch);
    }
  }, [userName])

  useEffect(() => {
    if (userSearch !== '' && actionView === 3) {
      setSearchBy('uuid')

      //console.log('seller in TB :' + userSearch);
      setFilterText(userSearch);
    }
  }, [userSearch]) //removed actionView userType    

  useEffect(() => {
    if (searchBy !== 'sellerId' || filterText === '') {
      setUserSearch('')
    }
  }, [searchBy, filterText])  // removed setUuidSearch

  const printItems = filteredItems.length > 0 ? filteredItems : keyListPrint;

  useEffect(() => {
    if (showPrintPreview) {
      try {
        handlePrint();
        //setShowPrintPreview(false); // Reset to prevent continuous pop-ups
      } catch (error) {
        console.error("Error in handlePrint:", error);
      }
    }
  }, [showPrintPreview, printItems, keyListPrint]);


  const handleClickPrint = () => {
    setShowPrintPreview(true);
    setTimeout(() => {
      setShowPrintPreview(false);
    }, 200);
    //setShowPrintPreview(true);

  };

  const handlePrint = async () => {

    if (printItems.length === 0 || keyList.length === 0 || filteredItems.length === 0) {
      enqueueSnackbar('No data to print!', { variant: 'error' });
      //setShowProgressBar(true);
      return;

    }

    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();

    const keyIndex = document.getElementById('keyId').value;
    const keyType = document.getElementById('keyTypeId').value;
    const userType = document.getElementById('valId').value;


    let statusContent = '';
    let typeContent = '';
    let userContent = '';

    if (keyIndex === '1') {
      statusContent = `<td>Extended Keys</td>`;
    } else if (keyIndex === '0') {
      statusContent = `<td>Disabled Keys</td>`;
    } else if (keyIndex === 'true') {
      statusContent = `<td>Generate Keys</td>`;
    } else if (keyIndex === 'false') {
      statusContent = `<td>Assign Keys</td>`;
    }

    if (userType === '0') {
      userContent = `<td>User</td>`;
    } else if (userType === '1') {
      userContent = `<td>Member</td>`;
    } else if (userType === '2') {
      userContent = `<td>All</td>`;
    }

    if (keyType === 'Permanent') {
      typeContent = `<td>Permanent</td>`;
    } else if (keyType === 'Temporary') {
      typeContent = `<td>Temporary</td>`;
    } else if (keyType === 'Perpetual') {
      typeContent = `<td>Perpetual</td>`;
    }

    const displayContent = statusContent && typeContent && userContent ? `
    <div class="status-container" style="width: 90%;">
        <table class="table">
          <thead>
            <tr>
            <th>User Type</th>
            <th>Key Type</th>
              <th>Status</th>
             
             
            </tr>
          </thead>
          <tbody>
            <tr>
            ${userContent}
            ${typeContent}
              ${statusContent}
              
              
            </tr>
          </tbody>
        </table>
      </div>
    ` : typeContent && userContent ? `
    <table class="table">
    <thead>
      <tr>
      <th>User Type</th>
      <th>Key Type</th>
       
       
       
      </tr>
    </thead>
    <tbody>
      <tr>
      ${userContent}
      ${typeContent}
        
        
        
      </tr>
    </tbody>
  </table>
</div>
` : statusContent && userContent ? `
<table class="table">
<thead>
  <tr>
  <th>User Type</th>
  <th>Status</th>
   
   
   
  </tr>
</thead>
<tbody>
  <tr>
  ${userContent}
  ${statusContent}
    
    
    
  </tr>
</tbody>
</table>
</div>
` :
      statusContent ? `
    <div class="status-container" style="width: 35%;">
        <table class="table">
          <thead>
            <tr>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              ${statusContent}
            </tr>
          </tbody>
        </table>
      </div>
    ` : typeContent ? `
    <div class="status-container" style="width: 35%;">
        <table class="table">
          <thead>
            <tr>
              <th>Key Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              ${typeContent}
            </tr>
          </tbody>
        </table>
      </div>
    `  : userContent ? `
    <div class="status-container" style="width: 35%;">
      <table class="table">
        <thead>
          <tr>
            <th>User Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            ${userContent}
          </tr>
        </tbody>
      </table>
    </div>
  ` : null;


    const searchByLabels = {
      uuid: 'UUID',
      customerName: 'Customer Name',
      days: 'Days',
      userCount: 'User Count',
      sellerName: 'Seller Name',
      // Add more labels as needed for other searchBy values
    };

    const searchByLabl = searchByLabels[searchBy] || searchBy;

    const extendedKeyStatusPromises = printItems.map(async item => {
      const realStatus = await fetchExtendedStatus(item.keyGenId);
      return realStatus;
    });

    // Wait for all promises to resolve
    const extendedKeyStatus = await Promise.all(extendedKeyStatusPromises);


    const printContent = `
      <html>
        <head>
          <!-- Include any necessary styles here -->
          <style>
          /* Define print-specific styles here */
          body {
            font-family: 'Calibri Light', sans-serif;
            
          }
          .print {
            margin-top: none;
          }
          .status-container {
            width: 35%;
            font-weight: bold;
          } 
          .header {
            font-size: 16px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .top-header {
            font-size: 16px;
            font-weight: bold;
            color: #595757; /* Light gray color */
          }
          .sub-header {
            font-size: 13px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .content {
            font-size: 13px;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
            
          }

          .table tbody { 
            padding-bottom: 30px;  
          }

          
          .table th {
            border: 1px solid #dddddd; /* Light gray border */
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table td {
            border: 1px solid #a6a6f5; /* Dark blue border */
            background-color: #fefefe;
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
        }
        
          .table th {
            background-color: #f2f2f2; /* Light gray background */
            color: #595757; /* Light gray color */
          }
          .search-info {
            font-size: 16px;
            font-weight: bold;
            color: #595757; /* Light gray color */
          }
          .search-info th {
            background-color: #f2f2f2; /* Light gray background */
            width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '25%' : '200px'}; 
          }
          .search-info td {
            width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '100px' : '80px'}; /* Set width based on searchBy value */
          }
          .search-container {
            
            width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '50%' : '200px'}; 
          }
          
          .footer {
            position: fixed;
            bottom: -10.5px;
            width: 100%;
            text-align: right;
            padding: 10px 0;
            
            border-top: 1px solid #ddd; /* Light gray border */
            margin-top: 50px;
        }
        
      
        
          
        </style>
      </head>
      
      <body>
      
      <div class="print">
         <div class="top-header">
   
        <div style="display: flex; justify-content: space-between; align-items: center;">
        <p style="margin: 0; font-size: 29px; ">LICENSE KEY DETAILS OF "${fullName.result ? fullName.result.fname.toUpperCase() : 'N/A'}" USER</p>
        <div style="text-align: right;">
        &nbsp; 
            <p style="color: #888888; margin: 0;">Date: ${printDateTime}</p>
            <p style="color: #888888; margin: 0;">User Name: ${fullName.result ? (fullName.result.fname + (fullName.result.lname ? ' ' + fullName.result.lname : '')) : 'N/A'}</p>
        </div>
       </div>
</div>
</div>

      
  
        <br>
       
        <div style="max-width: ${searchBy.trim() !== '' || typeContent.trim() !== '' || statusContent.trim() !== '' ? '600px' : '150px'};"> <!-- Adjust the max-width as needed -->
        <!-- Display the key status information and search information -->
      <table class="table"  style="width: 100%;">
        <thead>
            <tr>
                ${searchBy.trim() !== '' ? `<th style="width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '200px' : '150px'};">${searchByLabl}</th>` : ''}
                <th style="width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '200px' : '150px'};">User Type</th>
                ${typeContent.trim() !== '' ? `<th style="width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '200px' : '150px'};">Key Type</th>` : ''}
                ${statusContent.trim() !== '' ? `<th style="width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '200px' : '150px'};">Status</th>` : ''}
            </tr>
        </thead>
        <tbody>
            <tr>
                ${searchBy.trim() !== '' ? `<td style="width: ${searchBy === 'uuid' || searchBy === 'customerName' ? '200px' : '150px'};">${filterText}</td>` : ''}
                ${userContent}
                ${typeContent}
                ${statusContent}
            </tr>
        </tbody>
        
    </table>
       
    </div>
    <hr style="border: 1px solid;">
    <br>
        <table class="table">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px;">Date</th>
              <th style="border: 1px solid #ddd; padding: 8px;">UUID</th>
              <th style="border: 1px solid #ddd; padding: 8px;">Description</th>
              <th style="border: 1px solid #ddd; padding: 8px;">Status</th>
              <th style="border: 1px solid #ddd; padding: 8px;">Customer Name</th>
              <th style="border: 1px solid #ddd; padding: 8px;">Users</th>
              <th style="border: 1px solid #ddd; padding: 8px;">Days</th>
              <th style="border: 1px solid #ddd; padding: 8px;">From Date</th>
              <th style="border: 1px solid #ddd; padding: 8px;">To Date</th>
              <th style="border: 1px solid #ddd; padding: 7px;">Key Type</th>
            </tr>
          </thead>
          <tbody>
            ${printItems.map((item, index) => `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.createdDate || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.uuid || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.description || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                ${item.isGenerated ? (
        new Date(item.validTo) < new Date() && item.days > 0 ? (
          'Expired'
        ) : (
          disabledKeyIds.includes(item.keyGenId) || disabledKeyGenIds.includes(item.keyGenId) ? (
            'Disabled'
          ) : (
            extendedKeyStatus[index] ? (
              'Extended'
            ) : (
              'Generate'
            )
          )
        )
      ) : (
        /* Render 'Assign' when the key is not generated */
        'Assign'
      )}
              </td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.customerName || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.userCount || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.days || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.validFrom || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.validTo || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 9px;">${item.keyType || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
          
        </table>
        
      
    
        
        <script>
        const printAction = () => {
            // Get the print component element
            const printElement = document.getElementById("pageNumber");
    
            if (printElement) {
                // Add page number
                let pageNumber = 1;
                printElement.innerText = pageNumber; // Set the initial page number
    
                window.onbeforeprint = () => {
                    // Reset the page number before printing
                    pageNumber = 1;
                };
    
                window.onafterprint = () => {
                    // Increment page number after printing
                    pageNumber++;
                    printElement.innerText = pageNumber;
                };
            }
    
            // Trigger the printing process
            window.print();
            window.close(); // Close the window after printing (optional)
        };
    
        // Call the printAction function when needed
        printAction();
    </script>
    
      </body>
    </html>
    `;



    // Open a new window and write the content for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(printContent);
    printWindow.document.close();




  };


  //console.log("Data User ", fullName)





  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        setSearchBy('');
        setUuidEdit('');
      }
    };



    return (

      <Col md='12' className="another-col" >
        <Row className='search-bar p-3 mb-2'>
          <Col md='6' className=" divider-right">
            <Row>
              <Col md='4'>
                <Form.Group >
                  <Form.Select
                    id='valId'
                    onChange={(e) => setVal(e.target.value)
                    }
                    value={val}  >
                    <option disabled value=''> User Type </option>
                    {FilterByUsers.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md='4'>
                <Form.Group >
                  <Form.Select
                    id='keyTypeId'
                    onChange={(e) => setKeyType(e.target.value)}
                    value={keyType}>
                    <option disabled value=''>Key Type</option>
                    {KeyTypeForManage.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}

                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md='4'> {/* New Filter */}
                <Form.Group >
                  <Form.Select
                    id='keyId'
                    onChange={(e) => setKeyIndex(e.target.value)}
                    value={keyIndex}>
                    <option disabled value=''>Status</option>
                    {FilterByKeys.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}
                    {FilterDisabledKeys.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}
                    {keyStatus.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}

                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <MDBCol md='6' className="divider-left">
            <MDBRow>
              <MDBCol md='5'>
                <Form.Group >
                  <Form.Select
                    id='keyTypeNum'
                    onChange={(e) => setSearchBy(e.target.value)}
                    value={searchBy}>
                    <option disabled value=''>Search By...</option>
                    {KeyViewSearch.map((options, index) => (
                      <option key={index} value={options.value}>{options.select}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </MDBCol>
              <MDBCol md='6'>
                <div className='d-flex align-items-center'>

                  <MDBCol md='10' className='me-0 pe-2 search-input'>
                    <Form.Control
                      id="search"
                      type="text"
                      placeholder="Search..."
                      aria-label="Search Input"
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)} />
                  </MDBCol>
                  <MDBCol md='3' className='ms-0 ps-0 align-self-center'>
                    <Button type="button" onClick={handleClear} style={{ marginRight: '10px' }} >
                      X
                    </Button>
                  </MDBCol>
                  {printAccess && (
                    <MDBCol md='2' className='ms-0 ps-0 align-self-center ml-1' >
                      <Button type="button" onClick={handleClickPrint} style={{ marginLeft: '6px' }}>
                        Print
                      </Button>
                    </MDBCol>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </Row>
        {showProgressBar && (
          <div className="progress-container">
            <div className="progress-bar" id="myProgressBar"></div>
          </div>
        )}
      </Col>

    );
  }, [filterText, resetPaginationToggle, searchBy, keyType, keyIndex, val, sellerSearch]);

  return (
    <>
      <KeyViewPopup
        open={openKeyPopup}
        setOpen={setOpenKeyPopup}
        uuid={uuidEdit}
        key={keyGenId}
        name={name}
        

      />

      <KeyHistoryPopup
        open={openKeyHistoryPopup}
        setOpen={setOpenKeyHistoryPopup}
        key={keyGenId}
      />

      <KeyAssignHistoryPopup
        open={openAssignHistoryPopup}
        setOpen={setOpenAssignHistoryPopup}
        key={keyGenId}
      />

      {openPopup && (
        <KeyDetailsPopup
          uuid={uuidEdit}
          isOpen={openPopup}
          onClose={() => {
            setOpenPopup(false);
            setUuidEdit(''); // Reset the state here
          }}
          fetch={() => fetchData(val)} />
      )}
      <EditUUIDPopup
        open={openEditUUID}
        setOpen={setOpenEditUUID}
        fetch={() => fetchData(val)}
      />
      <ConfirmDialog
        open={openEdit}
        msg={editConfirm}
        setOpen={setOpenEdit}
        onYes={() => handleEdit()}
      />
      <ConfirmDialog
        open={openExtend}
        msg={extendConfirm}
        setOpen={setOpenExtend}
        onYes={() => handleExtend()}
      />
      <ConfirmDialog
        open={openDeActiveA}
        msg={deleteConfirm}
        setOpen={setOpenDeActiveA}
        onYes={() => handleDeActiveA()}
      />
      <ConfirmDialog
        open={openDeActiveG}
        msg={deleteConfirm}
        setOpen={setOpenDeActiveG}
        onYes={() => handleDeActiveG()}
        fetch={() =>
          fetchData(val)
        }
      />

      <ConfirmDialog
        open={openDelete}
        msg={deleteKeyConfirm}
        setOpen={setOpenDelete}
        onYes={async () => {
          // Pass the keyGenId when "Yes" is clicked
          await actionDelete(keyGenId);
        }}
        fetch={() => fetchData(val)}
      />

      <ConfirmDialog
        open={openEnable}
        msg={enableConfirm}
        setOpen={setOpenEnable}
        onYes={() => actionEnable(keyGenId)}
      />

      <SnackbarAlerts
        open={openAlert}
        setOpen={setOpenAlert}
        msg={alertMsg}
        severity={severity}
      />

      {/* <AddKeyPopup open={openAddKey} setOpen={setOpenAddKey} /> */}

      <DataTable
        columns={columns}
        striped
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        responsive
        customStyles={customStyles}


      />

    </>
  );
}

export default Table;