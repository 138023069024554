import React, {useState, useEffect, useContext} from 'react'
import TableBase from './TableBase';
import { MDBRow, MDBCol, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from '../../api/axios';
import SnackbarAlerts from "../SnackbarAlerts";
import DataContext from '../../context/DataContext';
import {Status} from '../../Data/Data'
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../Data/Data'
import DataTable from 'react-data-table-component';
import ConfirmDialog from '../ConfirmDialog';

const INDUSTRY_URL = process.env.REACT_APP_API_INDUSTRY_FIND_ALL;  
const INDUSTRY_BY_ID = process.env.REACT_APP_API_INDUSTRY_FIND_BY_ID; 
const INDUSTRY_SAVE = process.env.REACT_APP_API_INDUSTRY_SAVE; 
const INDUSTRY_UPDATE = process.env.REACT_APP_API_INDUSTRY_UPDATE; 
const INDUSTRY_DELETE = process.env.REACT_APP_API_INDUSTRY_DELETE; 

const Industry = () => {

  let variant

  const {isEmpty} = useContext(DataContext)
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Msg, setMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');   
  
  const [dataId, setDataId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this field?"
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('')
  const [editObj, setEditObj] = useState({})
  
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      wrap: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      wrap: true,
    },
    {
      name: 'Status',
      selector: row => (row.status === true )?'Active':'De-active',
      wrap: true,
      width: '150px',
    },
    {
      name: 'Actions',
      button: true,
      width: '300px',  
      selector: row => row.vatNo,
      cell: (row) => (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>          
          <Button
            className="edit-button"
            variant="warning"
            onClick={() => actionClick(row.id, 'edit')}
          >
              Edit
          </Button>
          <Button 
            className={row.status === true ? "delete-button" : 'hide'}
            variant="danger"
            onClick={() => enableDisable(row.id, 'disable', row.name, row.description)}
            >
              Disable 
          </Button>
          <Button 
            className={row.status === false ? "success-button" : 'hide'}
            variant="success"
            onClick={() => enableDisable(row.id, 'enable', row.name, row.description)}
            >
              Enable 
          </Button>
        </div>
          
      ),
    }, 
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '50px',
             // override the row height
        },
    },
    headCells: {
        style: {
          borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px' ,
            borderCollapse: 'collapse',
            fontWeight: 'bold',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#b2ebf2',
        },
    },
    cells: {
        style: {
            overFlowWrap: 'break-word',
            borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px' ,
            borderCollapse: 'collapse',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center'                
            
        },
    },
  };
  

  const actionClick = (id, action) => {
    setDataId(id); 
    if(action === 'edit'){
      setOpenEdit(true);
    }
  } 
  
  const enableDisable = (id, action, name, description) =>{
    setDataId(id); 
    if(action === 'disable'){
      setOpenDelete(true);
      setConfirmMsg("Are you sure you want to disable this field?")
      setEditObj({id, status:false, name, description})
    }else if(action === 'enable'){
      setOpenDelete(true);
      setConfirmMsg("Are you sure you want to enable this field?")
      setEditObj({id, status:true, name, description})
    }
  }
  
    const handleEnableDisable = async () =>{
      setOpenDelete(false)
      try {
        const response = await axios.put(INDUSTRY_UPDATE , editObj);      
        const status = response.data.status;
        const msg = response.data.msg;
        if(status){
          variant = 'success'
          fetchIndustry();
          enqueueSnackbar('Updated Successfully!', {variant})
          setDataId(0)
        }else{
          variant = 'error'
          enqueueSnackbar(msg, {variant})
        }
      } catch (err) {
        variant = 'error'
        if(!err.response){
          enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
        }else if(err.response !== 403){
          enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
        }
        setDataId(0)
      }
    }
    
  //fetch all the industries
  const fetchIndustry = async () => {            
    try {
        const response = await axios.get(INDUSTRY_URL+`/2`);
        const status = response.data.status;
        const result = response.data.result;
        const msg = response.data.msg;
          if(status){
            setData(result);
          }else{               
            setData([]);
            variant = 'error'
            enqueueSnackbar(msg, {variant})
          }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }     
    }
  }

  useEffect(() => {
    fetchIndustry();
  },[]);
  
  const getIndustryById = async () => {   
    setOpenEdit(false); 
    try {
      const response = await axios.get(INDUSTRY_BY_ID + `${dataId}`); 
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if(status){
        setName(result.name);
        setDescription(result.description);
        setStatus(result.status); 
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }        
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

   //handle submit method
  const handleSubmit = async(e) => {    
    e.preventDefault();
    const existingIndustries = await fetchExistingIndustries();
  const isDuplicate = existingIndustries.some(industry => industry.name.toLowerCase() === name.toLowerCase());

  if (isDuplicate) {
    enqueueSnackbar('Industry name already exists. Please use a different name.', { variant: 'error' });
    return; 
  }
    const newData = {name, description};
    try {        
      const response = await axios.post(INDUSTRY_SAVE, newData);
      const status = response.data.status;
      const msg = response.data.msg;   
      if(status){
        variant = 'success'
        enqueueSnackbar('Adding New Industry Successful!', {variant})
        setName('')
        setDescription('')
        setStatus('')
        fetchIndustry();
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }  
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

  const fetchExistingIndustries = async () => {
    try {
      const response = await axios.get(INDUSTRY_URL+`/2`);
      return response.data.result; 
    } catch (err) {
      let variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response.status !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      return []; 
    }
  };

  const handleEdit = async(e) => {    
    e.preventDefault();
    if(status === "true"){
      setStatus(true);
    }else if(status === "false"){
      setStatus(false);
    }
    const updateData = {id:dataId, name, description, status};
    try {
      const response = await axios.put(INDUSTRY_UPDATE , updateData);
      const status = response.data.status;
      const msg = response.data.msg;
      if(status){
        variant = 'success'
        enqueueSnackbar('Updated Successfully!', {variant})
        setDataId(0)
        setName('')
        setDescription('')
        setStatus('')
        fetchIndustry();
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      } 

    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
      setDataId(0)
    }
  }

  return (
    <>   
    <ConfirmDialog 
      open={openEdit}  
      msg={editConfirm} 
      setOpen={setOpenEdit} 
      onYes={()=>getIndustryById()}
    />
    <ConfirmDialog 
      open={openDelete}  
      msg={confirmMsg} 
      setOpen={setOpenDelete} 
      onYes={()=>handleEnableDisable()}
    />       
    <SnackbarAlerts 
      open={open} 
      setOpen={setOpen} 
      msg={Msg} 
      severity={severity}
    />  
    <MDBCard className='card-table'>
      <MDBCardBody className='p-0'>
        <MDBRow className='bg-blue p-3 mb-2'>
        <Form>
          <MDBRow>
            <MDBCol md='3' className='mb-1 mu-1'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Industry Name</Form.Label>    
                <span className="mandatory"> *</span>                   
                <Form.Control  
                  type='text'
                  id='nameId'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />            
              </Form.Group>
            </MDBCol>
            <MDBCol md='3' className='mb-1 mu-1'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Description</Form.Label>    
                <span className="mandatory"> *</span>                   
                <Form.Control 
                  type='text' 
                  id='desId'
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />            
              </Form.Group>                 
            </MDBCol> 
            <MDBCol md='3'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Status</Form.Label>    
                <span className="mandatory"> *</span> 
                  <Form.Select
                    id='statusId'
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option disabled value=''>Status...</option>
                    {Status.map((options, index) => (
                    <option key={index} value={options.value}>{options.select}</option>
                    ))}
                  </Form.Select>          
              </Form.Group>              
            </MDBCol>              
            <MDBCol md='3' className='mb-3'>              
              <Button 
                className={dataId === 0 ? '' : 'hide-row'}
                style={{ marginTop: '30px', width: '100%'}}
                onClick={handleSubmit}
                disabled={isEmpty(name) || isEmpty(description) || isEmpty(status)}
              >
                Add New
              </Button>  
              <Button 
                className={dataId === 0 ? 'hide-row' : ''}
                style={{ marginTop: '30px', width: '100%'}}
                onClick={handleEdit}
                disabled={isEmpty(name) || isEmpty(description) || isEmpty(status)}
              >
                Edit
              </Button>                 
            </MDBCol>                                        
          </MDBRow> 
        </Form>             
        </MDBRow>
        <MDBRow className='bg-white p-3 h-80'>
          <MDBCol md='12'>
            <MDBRow>
              <DataTable
                columns={columns}
                data={data}
                pagination
                persistTableHead
                responsive
                customStyles={customStyles}
              />              
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
    </>
  )
}

export default Industry