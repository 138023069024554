// To display Extended Key details not only the key
import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';

import axios from '../../api/axios';
import { GrClose } from 'react-icons/gr';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
import AuthContext from '../../context/AuthProvider';
//import Textarea from '@mui/joy/Textarea';
import Button from 'react-bootstrap/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import ExtendedKeyTable from './ExtendedKeyTable';
import { ErrorMsgs } from '../../Data/Data';


const GET_KEY = process.env.REACT_APP_API_GET_KEY;
const OLD_GET_KEY = process.env.REACT_APP_API_GET_OLD_KEY;
const CHECK_KEY_EXTEND = process.env.REACT_APP_API_CHECK_KEY_EXTEND;

const KeyViewPopup = (props) => {

  let variant

  const { keyGenId, uuidEdit, setUuidEdit, setKeyGenId } = useContext(KeyGenData)
  const { fullName } = useContext(DataContext)
  const { printAccess, oldKeySave } = useContext(AuthContext)

  const [licenseKey, setLicenseKey] = useState('');
  const [oldLicenseKey, setOldLicenseKey] = useState('')
  const [copyState, setCopyState] = useState(false);
  const [oldCopyState, setOldCopyState] = useState(false);
  const [keyType, setKeyType] = useState('');

  //const [openAlert, setOpenAlert] = useState(false);
  //const [alertMsg, setAlertMsg] = useState('');
  //const [severity, setServerity] = useState('success');
  //const [selectedUuid, setSelectedUuid] = useState(null);
  const [extendedKeyDetails, setExtendedKeyDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);


  useEffect(() => {
    const fetchExtendedKeyDetails = async (keyGenId) => {
      setLoadingDetails(true);
      try {
        const response = await axios.get(CHECK_KEY_EXTEND + `${keyGenId}/1`);
        const data = response.data;
        const status = data.status;
        const result = data.result;
        const msg = data.msg;
        // console.log("API Extend ", response.data);

        if (status) {
          setExtendedKeyDetails(result.historyList);
          setUuidEdit('');
          //console.log("Extend Details ", result.historyList); // Move the log statement here
        } else {
          variant = 'error';
          enqueueSnackbar(msg, { variant });
        }
      } catch (err) {
        variant = 'error';
        // Handle error for extended key details
      } finally {
        setLoadingDetails(false);
      }
    };

    if (keyGenId !== '' && props.open) {
      fetchExtendedKeyDetails(keyGenId);
      setUuidEdit('');
    }
  }, [keyGenId, props.open]);




  useEffect(() => {

    //Get data for available key and available free key 
    const fetchKey = async () => {

      const postData = { uuid: uuidEdit }
      try {
        const response = await axios.post(GET_KEY, postData);
        const data = response.data;
        const status = data.status;
        const result = data.result;
        const msg = data.msg
        if (status) {
          setLicenseKey(result.fullKey)
          setKeyType(result.keyType);

          //setUuidEdit('')
        }
        else {
          variant = 'error'
          enqueueSnackbar(msg, { variant })
        }
      } catch (err) {
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
        }
      }
    }
    if (uuidEdit !== '' && props.open) {
      fetchKey(uuidEdit);
    }

  }, [uuidEdit, props.open])

  useEffect(() => {
    // Get data for available key and available free key
    const fetchOldLicense = async () => {
      const postData = { uuid: uuidEdit, keyGenId: keyGenId }; // Include keyGenId in the post data

      try {
        const response = await axios.post(OLD_GET_KEY, postData); // Update to your old license endpoint URL
        const data = response.data;
        const status = data.status;
        const result = data.result;
        const msg = data.msg;

        if (status) {
          setOldLicenseKey(result.fullKey);
        } else {
          const variant = 'error';
          enqueueSnackbar(msg, { variant });
        }
      } catch (err) {
        const variant = 'error';
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
        } else if (err.response.status !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
        }
      }
    };

    // Check if uuid and keyGenId are available before fetching
    if (uuidEdit !== '' && keyGenId && props.open) {
      fetchOldLicense(uuidEdit, keyGenId);
    }

  }, [uuidEdit, keyGenId, props.open]);

  //download license key as a lic file
  const downloadTxtFile = () => {

    const fileData = licenseKey
    const element = document.createElement("a");
    const file = new Blob([fileData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = props.name + "_license.lic";
    //console.log("PROPS", props)
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const downloadOldTxtFile = () => {

    const fileData = oldLicenseKey
    const element = document.createElement("a");
    const file = new Blob([fileData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = props.name + "_old_license.lic";
    //console.log("PROPS", props)
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const handleClose = (reason) => {

    props.setOpen(false);
    setUuidEdit('');
    setKeyGenId('');
    setKeyType('');

  }

  const handleCopy = () => {
    setCopyState(true); // Set copyState to true when the user clicks the "Copy" button
    setTimeout(() => {
      setCopyState(false);
    }, 2000);
  };

  const handleOldCopy = () => {
    setOldCopyState(true); // Set copyState to true when the user clicks the "Copy" button
    setTimeout(() => {
      setOldCopyState(false);
    }, 2000);
  };

  // Inside your component

  // Inside your table rendering





  /* console.log("extendedKeyDetails", extendedKeyDetails);*/
  //console.log("isExtended", extendedKeyDetails);

  //console.log("ExtendedKeyTable props:", extendedKeyDetails);

  return (
    <div className={props.open ? 'glass-effect' : ''}>
      <Popover
        open={props.open}
        onClose={handleClose}

        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            overflow: 'hidden', // Add this line to hide overflow
          },
        }}
      >
        <div className='table-div'>
          <div className='end-div p-0'>
            <GrClose className='close-button' onClick={() => props.setOpen(false)} />
          </div>

          <MDBRow className='m-3'>

            <MDBCol md="12" className="bg-white p-2" style={{ width: extendedKeyDetails ? '800px' : '400px' }}>



              <MDBRow className='mb-3'>

                <MDBCol>



                  {loadingDetails ? (
                    <p style={{ textAlign: 'center', margin: '0' }}>Loading...</p>
                  ) : (
                    extendedKeyDetails && extendedKeyDetails.length > 0 ? (
                      <ExtendedKeyTable extendedKeyDetails={extendedKeyDetails} props={props} />
                    ) : (
                      <p style={{ textAlign: 'center', margin: '0' }}>No extended key details available.</p>
                    )
                  )}










                  {/*   {extendedKeyDetails && <ExtendedKeyTable extendedKeyDetails={extendedKeyDetails} props={props} />}  */}
                  {/*  <Textarea
                        className='no-overFlow'
                        style={{ fontSize: '12px' }}
                        id="licenseKeyId"
                        placeholder="License Key…"
                        disabled 
                        minRows={29}
                        maxRows={29}
                        onChange={(e) => setLicenseKey(e.target.value)}
                        value={licenseKey}
                      /> */}
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-3' style={{ justifyContent: 'space-between' }}>
                {!oldKeySave || keyType !== 'PERPETUAL' && (
                  <MDBCol md="auto">
                    <div className="button-pair-container" style={{
                      border: '2px solid #636363',
                      padding: '10px',
                      borderRadius: '8px',
                      textAlign: 'center',
                      display: 'inline-block',
                      width: '380px'
                    }}>
                      <p style={{ marginTop: '-23px', backgroundColor: 'white', width: '150px', fontWeight: 'bold' }}>Old Version [v1]</p>
                      <Button color='primary' className='m-1' disabled={!oldLicenseKey} onClick={downloadOldTxtFile}>
                        Save as file
                      </Button>
                      <CopyToClipboard text={oldLicenseKey} onCopy={handleOldCopy}>
                        <Button color='primary' className='m-1' disabled={!oldLicenseKey}>
                          {oldCopyState ? 'Copied' : 'Copy'}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </MDBCol>
                )}

                <MDBCol md="auto">
                  <div className="button-pair-container" style={{
                    border: '2px solid #636363',
                    padding: '10px',
                    borderRadius: '8px',
                    textAlign: 'center',
                    display: 'inline-block',
                    width: (!oldKeySave || keyType === 'PERPETUAL' ) ? '780px' : '380px'
                  }}>
                    <p style={{ marginTop: '-23px', backgroundColor: 'white', width: '150px', marginLeft: (!oldKeySave || keyType === 'PERPETUAL' ) ? '16px' : '204px', fontWeight: 'bold' }}>New Version [v2]</p>
                    <Button color='primary' className='m-1' disabled={!licenseKey} onClick={downloadTxtFile}>
                      Save as file
                    </Button>
                    <CopyToClipboard text={licenseKey} onCopy={handleCopy}>
                      <Button color='primary' className='m-1' disabled={!licenseKey}>
                        {copyState ? 'Copied' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </MDBCol>
              </MDBRow>




            </MDBCol>

          </MDBRow>

        </div>
      </Popover>
    </div>

  );
}

export default KeyViewPopup