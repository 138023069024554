import { createContext, useState, useEffect } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {

  const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;

    const [dataId, setDataId] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [loggedUser, setLoggedUser] = useState('')
    const [uuidSearch, setUuidSearch] = useState('')
    const [sellerSearch, setSellerSearch] = useState('')
    const [customerSearch, setCustomerSearch] = useState('')
    const [userSearch, setUserSearch] = useState('')
    const [transferCustomerId, setTransferCustomerId] = useState('');
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [actionView, setActionView] = useState(0)
    const [disabledKeyGenIdSeller, setDisabledKeyGenIdSeller] = useState("");
  const [activeKeyGenIdSeller, setActiveKeyGenIdSeller] = useState("");
  const [disabledKeyGenIdCustomer, setDisabledKeyGenIdCustomer] = useState("");
  const [activeKeyGenIdCustomer, setActiveKeyGenIdCustomer] = useState("");
  const [ isExtendedKey , setIsExtendedKey ] = useState(false)
  const [ userType , setUserType ] = useState("");
  const [keyListPrint, setKeyListPrint] = useState([]);
  const [fullName, setFullName] = useState([]);
  const [sellerKeys, setSellerKeys] = useState([]);
  const [customerKeys, setCustomerKeys] = useState([]);
  const [subscription, setSubscription] = useState(false);
  const [sellerId, setSellerId] = useState('')

 
    
    /* const [adminAccess, setAdminAccess] = useState(false);
    const [partnerAccess, setPartnerAccess] = useState(false);
     */
    const isEmpty = (val) => {
      if(val === ''){
        return true
      }else{
        return false
      }
    }

    /* useEffect(() => {
      console.log('Transfer Customer ID updated:', transferCustomerId);
    }, [transferCustomerId]); */

    const isEmptyArray = (val) => {
      if(val.length === 0){
        return true
      }else{
        return false
      }
    }
    
    return(
        <DataContext.Provider value ={{ 
            dataId, setDataId, filteredData, setFilteredData,disabledKeyGenIdCustomer, setDisabledKeyGenIdCustomer, customerKeys, setCustomerKeys,
            isEmpty, UUID_REGEX, loggedUser, setLoggedUser, activeKeyGenIdCustomer, setActiveKeyGenIdCustomer, sellerKeys, setSellerKeys,
            uuidSearch, setUuidSearch, sellerSearch, setSellerSearch, userSearch, setUserSearch, actionView, setActionView,sellerId, setSellerId,
            isEmptyArray,  activeKeyGenIdSeller, setActiveKeyGenIdSeller, disabledKeyGenIdSeller, setDisabledKeyGenIdSeller,
            userName, setUserName, customerSearch, setCustomerSearch, transferCustomerId, setTransferCustomerId, email, setEmail,
             isExtendedKey , setIsExtendedKey, userType , setUserType, keyListPrint, setKeyListPrint, fullName, setFullName, subscription, setSubscription,
            
         }}>
            {children}
         </DataContext.Provider>
    )
}

export default DataContext;