import React, { useEffect, useState, useRef, useContext } from 'react';
//import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer}from 'mdb-react-ui-kit';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import Textarea from '@mui/joy/Textarea';
import axios from '../../api/axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './TextCard.css';
import './OfflineActivation.css';
import Button from 'react-bootstrap/Button';
import TypeAheadClear from '../TypeAheadClear';
import SnackbarAlerts from '../SnackbarAlerts';
import DialogYesNo from '../DialogYesNo';
import { useNavigate, useLocation } from 'react-router-dom';
import { UilTimes, UilCheck } from "@iconscout/react-unicons";
import { KeyTypes, DaysOptions, YearsOptions, LicTypes, getKeyTypesForLicType } from '../../Data/Data';
import DataContext from '../../context/DataContext';
import KeyGenData from '../../context/KeyGenData';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, Variables } from '../../Data/Data'
import UnlimitedPopUp from './UnlimitedPopUp';

//import { Container, Row, Col, Card  } from 'react-bootstrap';

/* const UUID_REGEX = /^[A-Z0-9]{5,15}-[A-Z0-9]{5,15}-[A-Z0-9]{5,15}-[A-Z0-9]{5,15}$/; */
const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;
const CUSTOMER_BY_SELLER = process.env.REACT_APP_API_CUSTOMER_FIND_BY_SELLER_ID;
const KEY_URL = process.env.REACT_APP_API_OLD_KEY;
const KEY_WALLET_URL = process.env.REACT_APP_API_GET_KEY_WALLET;
const UUID_SEARCH = process.env.REACT_APP_API_CHECK_UUID;

const OldKeyGenerate = () => {

    let variant

    function useValidation(value, regex) {
        const [isValid, setIsValid] = useState(false);

        const validate = () => {
            setIsValid(regex.test(value));
        };

        return [isValid, validate];
    }

    const customerRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation()
    const from = location.state;
    const [edit, setEdit] = useState(false)
    const [isKeyExpired, setIsKeyExpired] = useState(false)

    const [customer, setCustomer] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerName, setCustomerName] = useState([]);
    const [customerId, setCustomerId] = useState();
    const [noCustomer, setNoCustomer] = useState(false);
    const [customerFocus, setCustomerFocus] = useState(false);
    const [passEndDate, setPassEndDate] = useState(false);

    const [keyType, setKeyType] = useState('');
    const [LicType, setLicType] = useState('Lic 2.0 (New)');
   


    const [open, setOpen] = useState(false);
    const [severity, setServerity] = useState('success');
    const [message, setMessage] = useState('');

    /* const [customDays, setCustomDays] = useState(''); */
    const [isChecked, setIsChecked] = useState(false);

    const minMonth = Variables.minMonth;
    //const [months, setMonths] = 
    const [checkMinMonths, setCheckMinMonths] = useState(false);
    const [nullMonths, setNullMonths] = useState(true)
    //const [monthsDisable, setMonthsDisable] = useState(false)

    const [days, setDays] = useState('15');
    const [nullDays, setNullDays] = useState(true)
    const [userCount, setUserCount] = useState('5');
    const [uuid, setUuid] = useState('');
    const [description, setDescription] = useState('');
    const [licenseKey, setLicenseKey] = useState('');
    const [userId, setUserId] = useState(0);
    const [selectedYears, setSelectedYears] = useState(minMonth);
    const [equivalentDays, setEquivalentDays] = useState('');
    const [subChecked, setSubChecked] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [keyWallet, setKeyWallet] = useState([]);
    const [available, setAvailable] = useState('');
    const [perpetualAvailable, setPerpetualAvailable] = useState('');
    const [freeAvailable, setFreeAvailable] = useState('');
    const [keys, setKeys] = useState(0);
    const [keyValidate, setKeyValidate] = useState(false);
    const [uuidValid, setUuidValid] = useState(false);

    const [copyState, setCopyState] = useState(false);
    const [status, setStatus] = useState(0)
    const [uuidChecked, setUuidChecked] = useState(true)
    const [usedUuid, setUsedUuid] = useState(false)
    const [validUuid, setValidUuid] = useState(false);
    const [isValidUuid, validateUuid] = useValidation(uuid, UUID_REGEX);
    const [uuidFocus, setUuidFocus] = useState(false);

    //const [spaces, setSpaces] = useState(false)

    const [openDialog, setOpenDialog] = useState(false);
    const [title, setTitle] = useState('');
    const [msg, setMsg] = useState('');
    const [erMsg, setErMsg] = useState('');
    const [generated, setGenerated] = useState(0);
    const [uuidStatus, setUuidStatus] = useState(true);
    const [sentRequest, setSendRequest] = useState(false);

    const val = 0
    const value = 2
    const { isEmpty, customerSearch, setCustomerSearch, } = useContext(DataContext);
    const { setValue } = useContext(KeyGenData);

    const [openUnlimited, setOpenUnlimited] = useState(false);
    const [unlimited, setUnlimited] = useState(false);
    const [subscription, setSubscription] = useState(false);

    //const [years, setYears] = useState('1');
    const maxUserCount = Variables.maxUserCount;




    const keyWalletFetch = async () => {
        try {
            const response = await axios.get(KEY_WALLET_URL);
            const status = response.data.status
            const result = response.data.result
            if (status) {
                if (result) {
                    setKeyWallet(result);
                    //console.log('result', result)
                } else {
                    setKeyWallet({ available: 0, perpetualAvailable: 0, freeAvailable: 0 })
                }
            } else {
                setKeyWallet({ available: 0, perpetualAvailable: 0, freeAvailable: 0 })
            }
        } catch (err) {
            variant = 'error'
            if (!err.response) {
                enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
            } else if (err.response !== 403) {
                enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
            }
        }
    }

    //console.log("Customer", customerSearch);

    const CustomerFetch = async (value) => {
        try {
            const response = await axios.get(CUSTOMER_BY_SELLER + `/${value}`);
            const status = response.data.status
            const result = response.data.result
            if (status) {
                if (result) {
                    setCustomerOptions(result.filter(item => item.status === true));
                } else {
                    setCustomerOptions([]);
                }
            } else {
                setCustomerOptions([]);
            }
        } catch (err) {
            variant = 'error'
            if (!err.response) {
                enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
            } else if (err.response !== 403) {
                enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
            }
        }
    }




    useEffect(() => {
        document.title = 'Custom Key Generate';
        keyWalletFetch();

        //console.log("Customer", customerSearch);
        if (customerSearch.length > 0) {
            CustomerFetch(2);
        } else {
            CustomerFetch(val);
            //setCustomerSearch('');

        }
    }, [])

    const calculateEndDate = (start, selectedYears, days, subChecked) => {
        if (!start) return ''; // Return empty string if no start date

        let calculatedDate = new Date(start);

        if (keyType === 'Permanent') {
            // If years are selected, add 372 days to the start date
            calculatedDate.setDate(calculatedDate.getDate() + (372 * parseInt(selectedYears)));
        } else if (keyType === 'Temporary') {
            // If days are selected, add the specified number of days
            calculatedDate.setDate(calculatedDate.getDate() + parseInt(days));
        } else if (keyType === 'Perpetual' && subChecked) {
            calculatedDate.setDate(calculatedDate.getDate() + (372 * parseInt(selectedYears)));

        } else {
            calculatedDate.setDate(calculatedDate.getDate());
        }

        return calculatedDate.toISOString().split("T")[0]; // Return formatted date (YYYY-MM-DD)
    };

    // Effect to update end date when start date or selections change
    /*  useEffect(() => {
         if (startDate) {
             const endDateValue = calculateEndDate(startDate, selectedYears, days, subscription);
             setEndDate(endDateValue);
         }
     }, [startDate, selectedYears, days, keyType, subscription]); */

    useEffect(() => {
        if (startDate) {
            const endDateValue = calculateEndDate(startDate, selectedYears, days, subscription);
            const today = new Date();
            const todayString = today.toISOString().split("T")[0]; // Current date in YYYY-MM-DD format

            // Validate the end date
            if (keyType === 'Perpetual' && !subscription) {
                // Allow past end dates for Perpetual keys without subscription
                setEndDate(endDateValue); // Set the end date without validation
                setPassEndDate(false); // Clear passEndDate state as it is valid in this case
            } else {
                // Regular validation for other key types or with subscription
                if (endDateValue < todayString) {
                    setPassEndDate(true);
                    const variant = 'error';
                    enqueueSnackbar('End date cannot be in the past.', { variant });
                    setEndDate(''); // Clear the end date if invalid
                    setStartDate(''); // Optionally clear the start date as well
                } else {
                    setPassEndDate(false); // End date is valid
                    setEndDate(endDateValue); // Set valid end date
                }
            }
        }
    }, [startDate, selectedYears, days, endDate, startDate, keyType, subscription]);

    useEffect(() => {
        // Reset years and days whenever keyType changes
        setSelectedYears('1');
        setDays('15');
    }, [keyType]);

    //validate uuid
    useEffect(() => {
        setValidUuid(UUID_REGEX.test(uuid));
        if (uuid.includes(' ')) {
            setUuid(uuid.trim())
        }
    }, [uuid])

    useEffect(() => {
        if (from) {
            setEdit(from[0])//0
            setCustomerId(from[1]);
        }
    }, [from])

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD
        setStartDate(today);
        setEndDate(today);
    }, []);

    //set default customer to generate key
    useEffect(() => {
        if ((customerOptions).find(option => option.id === customerId)) {
            setCustomer([(customerOptions).find(option => option.id === customerId)]);
        }
    }, [customerOptions, customerId]);

    //set available and freeUsed from key wallet 
    // freeUsed = temporary generated
    useEffect(() => {
        setAvailable(keyWallet.available);
        setFreeAvailable(keyWallet.freeAvailable);
        setPerpetualAvailable(keyWallet.perpetualAvailable);
    }, [keyWallet])

    useEffect(() => {
        //check whether months or days null to enable button
        if (keyType === 'Permanent') {
            if (selectedYears === '') {
                setNullMonths(true)
                setNullDays(false)
            } else {
                setNullMonths(false)
                setNullDays(false)
            }
        } else if (keyType === 'Temporary') {
            if (days === '') {
                setNullDays(true)
                setNullMonths(false)
            } else {
                setNullMonths(false)
                setNullDays(false)
            }
        } else if (keyType === 'Perpetual') {
            if (selectedYears === '') {
                setNullMonths(true)
                setNullDays(false)
            } else {
                setNullMonths(false)
                setNullDays(false)
            }
        }

        //console.log("Years :", selectedYears);

        //get the essential key count on months/days and userCount change
        if (selectedYears !== '' && userCount !== '') {
            let monthsInt = parseInt(selectedYears)
            let daysInt = parseInt(days)
            let userCountInt = parseInt(userCount)
            if (keyType === 'Permanent') {
                let essentialKeys = monthsInt * userCountInt
                setKeys(essentialKeys)
            } else if (keyType === 'Temporary') {
                let essentialKeys = daysInt * userCountInt
                setKeys(essentialKeys)
            } else if (keyType === 'Perpetual') {
                let essentialPerKeys = monthsInt * userCountInt;
                setKeys(subChecked ? essentialPerKeys : 0);
            }
            else if (keyType === '') {
                setKeys(0)
            }
        }

        //console.log("Years :", selectedYears);
        /* if(months !== '' && keyType === 'Permanent')  {
          if(months < 12){
            setCheckMinMonths(true)
          }else{
            setCheckMinMonths(false)
          }
        }else{
          setCheckMinMonths(false)
        } */
    }, [selectedYears, userCount, days, subChecked, keyType])


    //validate essential keys on keys change
    useEffect(() => {
        if (keyType === 'Permanent') {
            if (keys > available) {
                setKeyValidate(true)
            } else {
                setKeyValidate(false)
            }
        } if (keyType === 'Perpetual') {
            if (keys > perpetualAvailable) {
                setKeyValidate(true)
            } else {
                setKeyValidate(false)
            }
        }
        if (keyType === 'Temporary') {
            if (keys > freeAvailable) {
                setKeyValidate(true)
            } else {
                setKeyValidate(false)
            }
        }
    }, [keys, available, freeAvailable, perpetualAvailable, keyType, keyValidate])

    useEffect(() => {
        // When there are no available keys, show an alert
        if (available === 0 && freeAvailable === 0 && perpetualAvailable === 0 && status === 0) {
            setOpenDialog(true);
            setTitle('No keys to generate!');
            setMsg('You have no keys available. Do you want to extend or view existing keys?');
        } else if (available === 0 && freeAvailable > 0) {
            setIsChecked(true);
        } else if (perpetualAvailable === 0 && freeAvailable > 0) {
            setIsChecked(true);
        } else if (perpetualAvailable > 0 && available === 0) {
            setIsChecked(false);
        } else if (perpetualAvailable === 0 && available > 0) {
            setIsChecked(false);
        } else if (perpetualAvailable > 0 && freeAvailable === 0) {
            setIsChecked(false);
        } else if (available > 0 && freeAvailable === 0) {
            setIsChecked(false);
        }
    }, [available, freeAvailable, perpetualAvailable, status]);



    const onYes = () => {
        setOpenDialog(false);
        navigate('/dashboard')
    }

    const onView = () => {
        setOpenDialog(false);
        setValue(3)
    }

    const onExtend = () => {
        setOpenDialog(false);
        setValue(2)
    }
    /*   const onNo = () => {
        navigate('/dashboard')
        setOpenDialog(false);
      } */

    useEffect(() => {
        if (customer.length !== 0) {
            //Get customer full name to display in download file 
            setCustomerName(customer[0].fname + customer[0].lname);
            setCustomerId(customer[0].id)
        } else {
            setNoCustomer(true)
        }
    }, [customer, generated])

    //check whether customer is empty for validation
    const validatecustomer = () => {
        if (noCustomer) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        //clear inputs when selected customer change  
        if (customerId === 0) {
            setKeyType('');
            setDays('15')
            setUserCount('5')
            setSelectedYears('1')
            setDescription('')
            setCustomer([])
        }
    }, [customerId])

    useEffect(() => {
        //clear inputs when selected customer change  
        if (keyType === 'Perpetual') {

            setUserCount('50')

        } else {
            setUserCount('5')
        }
    }, [keyType])

    useEffect(() => {
        //open unlimited popup if user count is 75 or more     
        if (userCount !== '') {
            setUserCount(parseInt(userCount))
        }

        if (typeof userCount === "number") {
            if (userCount >= maxUserCount) {
                setOpenUnlimited(true);
                setUserCount(maxUserCount);
                setUnlimited(true)
            } else {
                setUnlimited(false)
            }
        }
    }, [userCount, maxUserCount])

    useEffect(() => {
        //if unlimited popup is opened and turn off unlimited clear user count field 
        if (openUnlimited && !unlimited) {
            setUserCount('5')
        } else if (unlimited) {
            setUserCount(maxUserCount)
        }
    }, [unlimited, openUnlimited, maxUserCount])

    const uuidSearch = async () => {
        try {
            const response = await axios.get(UUID_SEARCH + `${uuid}`);
            const data = response.data
            //console.log('key', data)
            //console.log('key status', data.status)
            setUuidStatus(data.status);
            if (data.status === false) {
                setUuidChecked(false)
                setUsedUuid(true)
                variant = 'success';
                enqueueSnackbar('A valid UUID.', { variant });
                const today = new Date().toISOString().split('T')[0]; // Get today's date
                const validTo = data.result.validTo;
                setUuidValid(true);


                if (today > validTo) {
                    setIsKeyExpired(true);
                    setUuidChecked(false)
                    setUsedUuid(false)

                } else {
                    setIsKeyExpired(false);

                }

            } else {
                setUuidChecked(true)
                setUsedUuid(false)
                setUuidValid(false)
                variant = 'error';
                enqueueSnackbar('This UUID is already used with a valid key.', { variant });
            }
        } catch (err) {
            variant = 'error'
            if (!err.response) {
                //enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
            } else if (err.response !== 403) {
                enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
            }
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setSendRequest(true);

        try {
            const response = await axios.get(UUID_SEARCH + `${uuid}`);
            const data = response.data;

            if (data.status) {
                if (isKeyExpired) {
                    setUuidChecked(true);
                    setUsedUuid(true);

                    let customerId = customer[0].id;
                    let monthsInt = parseInt(selectedYears);
                    let daysInt = parseInt(days);

                    if (keyType === 'Temporary') {
                        monthsInt = 0;
                    } else {
                        daysInt = 0;
                    }

                    let userCountInt = parseInt(userCount);
                    let keyTypeUpper = keyType.toUpperCase();
                    const equivalentPerDays = subscription ? parseInt(selectedYears) * 372 : 0;

                    const getNewKey = {
                        cuId: customerId,
                        customDate: "2012-01-01",
                        uuid,
                        description,
                        keyType: keyTypeUpper,
                        userCount: userCountInt,
                        subscription,
                        userId,
                        isFree: isChecked,
                        startDate: startDate, // Pass start date to backend
                        endDate: endDate,
                        licenseType: LicType,
                    };

                    if (keyType === 'Permanent') {
                        getNewKey.days = equivalentDays;
                    } else if (keyType === 'Temporary') {
                        getNewKey.days = daysInt;
                    } else if (keyType === 'Perpetual') {
                        getNewKey.days = equivalentPerDays;
                    }

                    try {
                        const response = await axios.post(KEY_URL, getNewKey);
                        const data = response.data;
                        setSendRequest(false);

                        if (data.status) {
                            setLicenseKey(data.result.fullKey);
                            variant = 'success';
                            enqueueSnackbar('Key Generation Successful!!', { variant });

                            // Reset all form fields and state variables including the subscription checkbox
                            setCustomer([]);
                            setCustomerFocus(false);
                            setKeyType('');
                            setDays('15');
                            setUserCount('5');
                            setSelectedYears('1');
                            setUuid('');
                            setDescription('');
                            setKeys(0);
                            setStatus(2);
                            setUuidChecked(true);
                            setUsedUuid(false);
                            setUuidFocus(false);
                            setSubscription(false); // Reset the subscription checkbox
                            keyWalletFetch();
                            setStartDate(new Date());
                            setEndDate(new Date());
                            setLicType('Lic 2.0 (New)');

                        } else {
                            variant = 'error';
                            enqueueSnackbar('Key Generation Fail!', { variant });
                        }

                    } catch (err) {
                        setSendRequest(false);
                        variant = 'error';
                        if (!err.response) {
                            enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
                        } else if (err.response !== 403) {
                            enqueueSnackbar('Key Generation Failed!', { variant });
                        }
                    }

                } else {
                    variant = 'info';
                    enqueueSnackbar('This UUID is already used with a valid key.', { variant });
                    setSendRequest(false);
                    setUuidChecked(false);
                    setUsedUuid(false);
                    setKeyType('');
                    setDays('15');
                    setUserCount('5');
                    setSelectedYears('1');
                    setDescription('');
                    setCustomer([]);
                    setCustomerFocus(false);
                    setSubscription(false); // Reset the subscription checkbox
                    setStartDate(new Date());
                    setEndDate(new Date());
                    setLicType('Lic 2.0 (New)');
                    return;
                }
            } else {
                setUuidChecked(false);
                setUsedUuid(false);

                let customerId = customer[0].id;
                let monthsInt = parseInt(selectedYears);
                let daysInt = parseInt(days);

                if (keyType === 'Temporary') {
                    monthsInt = 0;
                } else {
                    daysInt = 0;
                }

                let userCountInt = parseInt(userCount);
                let keyTypeUpper = keyType.toUpperCase();
                const equivalentPerDays = subscription ? parseInt(selectedYears) * 372 : 0;

                const getNewKey = {
                    cuId: customerId,
                    customDate: "2012-01-01",
                    uuid,
                    description,
                    keyType: keyTypeUpper,
                    userCount: userCountInt,
                    subscription,
                    userId,
                    isFree: isChecked,
                    startDate: startDate, // Pass start date to backend
                    endDate: endDate, // Pass end date to backend
                    licenseType: LicType

                };

                if (keyType === 'Permanent') {
                    getNewKey.days = equivalentDays;
                } else if (keyType === 'Temporary') {
                    getNewKey.days = daysInt;
                } else if (keyType === 'Perpetual') {
                    getNewKey.days = equivalentPerDays;
                }

                try {
                    const response = await axios.post(KEY_URL, getNewKey);
                    const data = response.data;
                    setSendRequest(false);

                    if (data.status) {
                        setLicenseKey(data.result.fullKey);
                        variant = 'success';
                        enqueueSnackbar('Key Generation Successful!!', { variant });

                        // Reset all form fields and state variables including the subscription checkbox
                        setCustomer([]);
                        setCustomerFocus(false);
                        setKeyType('');
                        setDays('15');
                        setUserCount('5');
                        setSelectedYears('1');
                        setUuid('');
                        setDescription('');
                        setKeys(0);
                        setStatus(2);
                        setUuidChecked(true);
                        setUsedUuid(false);
                        setUuidFocus(false);
                        setSubscription(false); // Reset the subscription checkbox
                        keyWalletFetch();
                        setStartDate(new Date());
                        setEndDate(new Date());
                        setLicType('Lic 2.0 (New)');

                    } else {
                        variant = 'error';
                        enqueueSnackbar('Key Generation Fail!', { variant });
                    }

                } catch (err) {
                    setSendRequest(false);
                    variant = 'error';
                    if (!err.response) {
                        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
                    } else if (err.response !== 403) {
                        enqueueSnackbar('Key Generation Failed!', { variant });
                    }
                }
            }
        } catch (err) {
            setSendRequest(false);
            variant = 'error';
            if (!err.response) {
                enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
            } else if (err.response !== 403) {
                enqueueSnackbar('UUID Check Failed!', { variant });
            }
        }
    };


    useEffect(() => {
        if (selectedYears !== '') {
            // Calculate equivalent days based on selected years (1 year = 372 days)
            const days = parseInt(selectedYears) * 372;
            setEquivalentDays(days);
        }
    }, [selectedYears]);


    //download license key as a lic file
    const downloadTxtFile = () => {
        const fileData = licenseKey
        const element = document.createElement("a");
        const file = new Blob([fileData], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = customerName + "_license.lic";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setStatus(status - 1);
    }

    const handleKeyDown = (event) => {
        // check if the key pressed is not a number or a control key
        if (!((event.keyCode >= 48 && event.keyCode <= 57)
            || (event.keyCode >= 96 && event.keyCode <= 105)
            || event.keyCode === 8 || event.keyCode === 9
            || event.keyCode === 37 || event.keyCode === 39
            || (event.ctrlKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Ctrl+C and Ctrl+V
            || (event.metaKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Cmd+C and Cmd+V on macOS
        )) {
            event.preventDefault();
        }
    };



    useEffect(() => {
        //console.log("Customer Options: ", customerOptions); // Debug the customer options
    }, [customerOptions]);


    const handleUnlimited = () => {
        if (unlimited) {
            setOpenUnlimited(false);
            setUnlimited(false);
            setUserCount(5); // Set uCountId to 5 when unlimited is unchecked
        } else {
            setOpenUnlimited(true);
            setUnlimited(true);
        }
    };


    const handleSubscription = (e) => {
        setSubscription(e.target.checked);
        const checked = e.target.checked;
        setSubChecked(checked);
        const currentDate = new Date().toISOString().split("T")[0]; // Get current date
        setEndDate(currentDate);

        // If keyType is 'Perpetual' and checkbox is unchecked, reset dates
        if (keyType === 'Perpetual' && !checked) {

            setStartDate(currentDate);
            setEndDate(currentDate);
        }


    };


    useEffect(() => {
        if (keyType !== 'Perpetual') {
            setSubscription(false);
            setSubChecked(false);
        }
    }, [keyType]);


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleKeyTypeChange = (event) => {
        const newKeyType = event.target.value;
        setKeyType(newKeyType);

        // Set current date for both start and end date
        const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
        setStartDate(currentDate);
        setEndDate(currentDate); // Reset end date to current date as well

        // Optionally reset years and days if needed

    };

    const handleLicTypeChange = (e) => {
        setLicType(e.target.value);
        setKeyType(''); // Reset keyType when LicType changes
    };

    const filteredKeyTypes = getKeyTypesForLicType(LicType);



    return (
        <Container fluid className='container-style small-width'>
            <UnlimitedPopUp
                open={openUnlimited}
                setOpen={setOpenUnlimited}
                unlimited={unlimited}
                setUnlimited={setUnlimited}
                userCount={userCount}
                setUserCount={setUserCount}
            />
            <Row className='d-flex justify-content-center align-items-center h-auto'>
                <Col xs={12}>
                    <Col col='12'>
                        <SnackbarAlerts
                            open={open}
                            setOpen={setOpen}
                            msg={message}
                            severity={severity}
                        />
                        <DialogYesNo
                            open={openDialog}
                            title={title}
                            msg={msg}
                            setOpen={openDialog}
                            onBack={() => onYes()}
                            onExtend={() => onExtend()}
                            onView={() => onView()}
                        />
                        <Card className='card-offline mt-3'>
                            <Card.Body className='p-0'>
                                <Row>
                                    <Col xs={12} md='6' className='bg-blue p-3 p-md-5' >
                                        <p className='user-instructions p-1'> To begin the key generation process enter the
                                            UUID and check the status of UUID.</p>
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md='12' className='mb-1 mu-1'>
                                                    <Form.Group className='mb-3 me-0 pe-0'>
                                                        <Form.Label className='white-lable me-0 pe-0'>UUID</Form.Label>
                                                        <span className="mandatory"> *</span>
                                                        <div style={{ display: 'flex' }}>
                                                            <Form.Control
                                                                className={
                                                                    !isEmpty(uuid)
                                                                        ? (!uuidValid && !isKeyExpired && uuidChecked
                                                                            ? 'not-valid'
                                                                            : 'valid-field'
                                                                        )
                                                                        : ''

                                                                }
                                                                type='text'
                                                                id='vatCodeid'
                                                                onChange={(e) => setUuid(e.target.value)}
                                                                required
                                                                value={uuid}
                                                                onFocus={() => setUuidFocus(true)}
                                                                disabled={!uuidChecked}
                                                                onBlur={validateUuid}
                                                            />
                                                            <Button
                                                                className='uuid-search ms-2'
                                                                variant='light'
                                                                onClick={() => uuidSearch()}
                                                                disabled={!validUuid}
                                                            >
                                                                check
                                                            </Button>
                                                        </div>

                                                        {/*  <span className={usedUuid && !isKeyExpired && isValidUuid ? 'validate' : 'hide'} style={{ color: '#038a3b' }}>Valid UUID</span> */}
                                                        <p className={uuidFocus && !validUuid && !isEmpty(uuid) ? 'validate' : 'hide'}>Invalid UUID</p>

                                                        {/*  <span className={!uuidValid && !isEmpty(uuid) && !usedUuid && !uuidChecked ? 'validate' : 'hide'}> This UUID is already used with a valid key </span> */}

                                                        {/*  Validate message for UUID */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <fieldset
                                                disabled={uuidChecked && !isKeyExpired}
                                                className={'fieldset-invisible'}
                                            >
                                                <Row>
                                                    <Col md='6' className='cust-column'>
                                                        <Form.Group className='mb-2' >
                                                            <Form.Label className='white-lable' size='sm'>Customers</Form.Label>
                                                            <span className="mandatory"> *</span>
                                                            <Typeahead
                                                                id="customersId"
                                                                labelKey={(customerOptions) => `${customerOptions.fname} ${customerOptions.lname}`}
                                                                onChange={(selected) => setCustomer(selected)}
                                                                options={customerOptions}
                                                                placeholder="Customer..."
                                                                selected={customer}
                                                                value={customer}
                                                                inputProps={{ required: true }}
                                                                ref={customerRef}
                                                                onFocus={() => setCustomerFocus(true)}
                                                                onBlur={validatecustomer}
                                                                disabled={(uuidChecked && !isKeyExpired) || edit}
                                                                style={{ display: 'flex' }}
                                                            ><TypeAheadClear select={customer} setSelect={setCustomer} className="clear-button" /></Typeahead>
                                                            <p className={customer.length === 0 && customerFocus ? "validate" : "offscreen"}>
                                                                Customer field cannot be empty. <br />
                                                            </p>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md='6' className='custom-column'>
                                                        <Form.Group className='mb-3' >
                                                            <Form.Label className='white-lable' size='sm'>License Version</Form.Label>
                                                            <span className="mandatory"> *</span>
                                                            <div style={{ position: 'relative', paddingLeft: 'none' }}>
                                                                <Form.Select
                                                                    id='LicTypeId'
                                                                    onChange={handleLicTypeChange}
                                                                    value={LicType}
                                                                    disabled={uuidChecked && !isKeyExpired}

                                                                >
                                                                    <option disabled value=''>License Version...</option>
                                                                    {LicTypes.map((options, index) => (
                                                                        <option key={index} value={options.value}>{options.select}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='6' className='c-column'>
                                                        <Form.Group className='mb-3' >
                                                            <Form.Label className='white-lable' size='sm'>Key Type</Form.Label>
                                                            <span className="mandatory"> *</span>
                                                            <div style={{ position: 'relative', paddingLeft: 'none' }}>
                                                                <Form.Select
                                                                    id='keyTypeId'
                                                                    onChange={handleKeyTypeChange}
                                                                    value={keyType}
                                                                    disabled={uuidChecked && !isKeyExpired}

                                                                >
                                                                    {/* <option disabled value=''>Key type...</option>
                                                                    {KeyTypes.map((options, index) => (
                                                                        <option key={index} value={options.value}>{options.select}</option>
                                                                    ))}  */}
                                                                    <option disabled value=''>Key type...</option>
                                                                    {filteredKeyTypes.map((option, index) => (
                                                                        <option key={index} value={option.value}>{option.select}</option>
                                                                    ))}


                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md='6' className='c-column'>
                                                        <Form.Group className={keyType === '' ? 'hide-field' : 'mb-2'}>
                                                            <Form.Label className='white-lable me-0 pe-0'>User Count</Form.Label>
                                                            <span className="mandatory"> *</span>&nbsp;



                                                            <Form.Check
                                                                inline
                                                                label={<span className="white-label">Unlimited</span>}
                                                                type="checkbox"
                                                                id="unlimitedCheckbox"
                                                                checked={unlimited || keyType === 'Perpetual'}
                                                                onChange={() => handleUnlimited(!unlimited)}
                                                                disabled={keyType === 'Perpetual'}
                                                                className="mr-3"
                                                            />

                                                            <Form.Control
                                                                type='number'
                                                                onChange={(e) => setUserCount(e.target.value)}
                                                                onKeyDown={handleKeyDown}
                                                                min={1}
                                                                max={maxUserCount}
                                                                id='uCountId'
                                                                required
                                                                value={userCount || (keyType === 'Perpetual' ? 50 : '')} // Set to 50 if keyType is 'Perpetual'
                                                                disabled={unlimited || keyType === 'Perpetual'} // Disable if unlimited is checked or keyType is 'Perpetual'
                                                                className="mr-2"
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md='6' className='custom-column'>
                                                        <Form.Group className={keyType === 'Temporary' ? 'mb-2' : 'hide-field'}>
                                                            <Form.Label className='white-lable' size='sm'>Days</Form.Label>
                                                            <span className="mandatory"> *</span>
                                                            <Form.Control
                                                                type='number'
                                                                id='daysId'
                                                                onChange={(e) => {
                                                                    let value = parseInt(e.target.value, 10);
                                                                    if (value < 1 || isNaN(value)) {
                                                                        value = 1;
                                                                    }
                                                                    value = Math.min(value, 45);
                                                                    setDays(value);
                                                                }}
                                                                value={days}
                                                                min={1} // Minimum days set to 1
                                                                max={45} // Maximum days set to 45
                                                                placeholder='Enter number of days'
                                                            />
                                                            {days > 45 && (
                                                                <span className="error-message" style={{ color: 'red' }}>
                                                                    Maximum allowed days is 45.
                                                                </span>
                                                            )}
                                                        </Form.Group>


                                                        {keyType === 'Perpetual' && (
                                                            <>
                                                                <Form.Label className='white-lable' size='sm'>Years</Form.Label>
                                                                <span className="mandatory"> * </span>
                                                                <Form.Check
                                                                    inline
                                                                    label={<span className="white-label">Subscription</span>}
                                                                    type="checkbox"
                                                                    id="subscriptionCheckbox"
                                                                    checked={subscription}
                                                                    onChange={handleSubscription} // Using the handleSubscription function
                                                                    className="mr-3"
                                                                />
                                                            </>
                                                        )}


                                                        <Form.Group className={keyType === 'Permanent' || (keyType === 'Perpetual' && subscription) ? 'mb-3' : 'hide-field'}>

                                                            {keyType === 'Permanent' && (
                                                                <>
                                                                    <Form.Label className='white-lable' size='sm'>Years</Form.Label>
                                                                    <span className="mandatory"> * </span>
                                                                </>
                                                            )}
                                                            <Form.Select
                                                                id='yearsId'
                                                                onChange={(e) => setSelectedYears(e.target.value)}
                                                                value={selectedYears}

                                                            >
                                                                <option disabled value=''>Years...</option>
                                                                {YearsOptions.map((options, index) => (
                                                                    <option key={index} value={options.value}>{options.select}</option>
                                                                ))}
                                                            </Form.Select>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='6' className='cust-column'>
                                                        <Form.Group className={keyType === '' ? 'hide-field' : 'mb-2'}>
                                                            <Form.Label className='white-lable' size='sm'>Start Date</Form.Label>
                                                            <Form.Control
                                                                type='date'
                                                                id='startDateId'
                                                                onChange={handleStartDateChange}
                                                                value={startDate}

                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md='6' className='custom-column'>
                                                        <Form.Group className={keyType === '' ? 'hide-field' : 'mb-3'}>
                                                            <Form.Label className='white-lable' size='sm'>End Date</Form.Label>
                                                            <Form.Control
                                                                type='date'
                                                                id='endDateId'
                                                                onChange={handleEndDateChange}
                                                                value={endDate}
                                                                min={new Date().toISOString().split('T')[0]}
                                                                readOnly
                                                                style={{ pointerEvents: 'auto' }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>



                                                <Row>
                                                    <Col md='12' className='cust-column'>
                                                        <Form.Group className='white-lable mb-3 me-0 pe-0'>
                                                            <Form.Label className='me-0 pe-0'>Description</Form.Label>
                                                            <Form.Control
                                                                as='textarea' // Use 'textarea' instead of 'text'
                                                                rows={4} // Specify the number of rows to increase the height
                                                                id='descriptionId'
                                                                onChange={(e) => setDescription(e.target.value)}
                                                                value={description}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div style={{ marginTop: '20px' }}></div>
                                                <Row >
                                                    <Col md='12' className='cust-column'>
                                                        <h6 className='card-text mb-3'>Permanent: {available}</h6>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md='12' className='cust-column'>
                                                        <h6 className='card-text mb-3'>Perpetual: {perpetualAvailable}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='12' className='cust-column'>
                                                        <h6 className='card-text mb-3'>Temporary: {freeAvailable}</h6>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md='12' className='cust-column'>
                                                        <h6 className={keyValidate ? 'validate-h6 mb-0' : 'card-text mb-3'}>Needed Keys : {keys}</h6>
                                                        <span className={keyValidate ? 'validate' : 'hide'}>Needed keys exceeded available keys</span>
                                                    </Col>
                                                </Row>
                                                <div style={{ marginTop: '32px' }}></div> {/* Add vertical space here */}
                                                <Row>
                                                    <Col md='6' className='pt-2 ps-4'>
                                                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check 
                            type="checkbox" 
                            id='freeCheckId'
                            label="Free" 
                            checked={isChecked}
                            disabled={available === 0 || freeAvailable === 0 ? true : false}
                            onChange={() => setIsChecked(!isChecked)}/>
                        </Form.Group>   */}
                                                    </Col>
                                                    <Col md='6' className='d-flex justify-content-end mb-3 text-start remove-padding-right' >
                                                        <Button
                                                            //disable the button when customer, keyType, userCount, months, days, uuid is empty
                                                            disabled={(customer.length === 0 || keyType === '' || LicType === '' || checkMinMonths || userCount === '' || passEndDate ||
                                                                keyValidate || uuid === '' || days === '0' || selectedYears === '' || selectedYears === '0' || selectedYears === '0' || nullMonths || nullDays || !validUuid || sentRequest) ? true : false}
                                                            color='primary'
                                                            type='submit'

                                                        >
                                                            Generate Key
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Form>
                                    </Col>
                                    <Col xs={12} md='6' className='bg-white p-3 p-md-5'>
                                        <Row className='mb-3'>
                                            <Col className='d-flex justify-content-end'>
                                                <Button
                                                    color='primary'
                                                    className='m-1'
                                                    disabled={licenseKey === ''}
                                                    onClick={downloadTxtFile}
                                                >
                                                    Save as file
                                                </Button>
                                                <CopyToClipboard
                                                    text={licenseKey}
                                                    onCopy={() => setCopyState(true)}
                                                >
                                                    <Button
                                                        color='primary'
                                                        className='m-1'
                                                        onClick={() => setStatus(status - 1)}
                                                        disabled={licenseKey === ''}
                                                    >
                                                        {copyState ? 'Copied' : 'Copy'}
                                                    </Button>
                                                </CopyToClipboard>
                                            </Col>
                                        </Row>


                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}

export default OldKeyGenerate