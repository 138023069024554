import React,{useState, useEffect} from 'react';
import { MDBRow, MDBCol}from 'mdb-react-ui-kit';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DataTable from 'react-data-table-component';
import Paper from "@mui/material/Paper";
import Form from 'react-bootstrap/Form';
import axios from '../../api/axios';
import SnackbarAlerts from "../SnackbarAlerts";
import { GrClose } from 'react-icons/gr';
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../Data/Data';
import CircularProgress from '@mui/material/CircularProgress';

const KEY_WALLET_URL = process.env.REACT_APP_API_GET_KEY_WALLET;
const KEY_ALLOCATE_URL = process.env.REACT_APP_API_KEYWALLET_ADD_KEYS;
const USER_KEY_WALLET = process.env.REACT_APP_API_GET_KEY_WALLET_BY_USER_ID;
const HISTORY_URL = process.env.REACT_APP_API_KEY_WALLET_HISTORY; //'http://localhost:8080/api/keyWallet/getHistoryByUserId/';



const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString(); 
};

const HistoryPopup = ({ open, onClose, onCloseAndOpenAddKey, historyData }) => {
  const [showPermanent, setShowPermanent] = useState(true);
  const [showTemporary, setShowTemporary] = useState(true);
  const [showPerpetual, setShowPerpetual] = useState(true);
  const [showAddedKeys, setShowAddedKeys] = useState(true); // State for showing added keys
  const [filteredData, setFilteredData] = useState([]);
  const [ initialLoad,  setInitialLoad] = useState(true); // State to check initial load


  useEffect(() => {
    if (!historyData || !Array.isArray(historyData)) return;
  
    let data = [];
  
    //console.log('added', historyData.filter(item => item.action === 'Increased'))
  
    // Check for specific key types based on checkbox toggles
    if (showPermanent) {
      const permanentKeys = historyData.filter(item => item.keyType === 'PERMANENT');
      data = [...data, ...permanentKeys];
    }
    if (showTemporary) {
      const temporaryKeys = historyData.filter(item => item.keyType === 'TEMPORARY');
      data = [...data, ...temporaryKeys];
    }
    if (showPerpetual) {
      const perpetualKeys = historyData.filter(item => item.keyType === 'PERPETUAL');
      data = [...data, ...perpetualKeys];
    }
    

    // If none of the checkboxes are checked, display no data
    if (!showPermanent && !showTemporary && !showPerpetual ) {
      data = [];
    }
  
    setFilteredData(data.reverse());
    setInitialLoad(false); // Set initial load to false after the first run
  }, [showPermanent, showTemporary, showPerpetual,  historyData]);
  

  // Define columns for DataTable, including the new Action column
  const columns = [
    { name: 'Date', selector: row => formatDate(row.createdDate), sortable: true },
    { name: 'Before Update', selector: row => row.beforeUpdate, sortable: true },
    { name: 'Updated Amount', selector: row => row.updatedAmount, sortable: true },
    { name: 'Remaining Amount', selector: row => row.remainingAmount, sortable: true },
    { name: 'Key Type', selector: row => row.keyType, sortable: true },
    { name: 'Action', selector: row => row.action, sortable: true }, // New Action column
  ];

  // Custom styles for DataTable
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overflowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'
      },
    },
  };

  return (
    <div className={open ? 'glass-effect' : ''}>
      <Popover
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: '740px', // Set the width of the popup
            height: 'auto', // Set the height of the popup
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className='table-div'>
          <div className='end-div p-0'>
            <GrClose onClick={() => { onClose(); }} />
          </div>
          <MDBRow className='m-3'>
            <MDBCol md='12'>
              <h6 className='card-text mb-3'>License Allocation History</h6>
              
              <div className='d-flex justify-content-center mb-3'>
                <label className='me-3 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    checked={showPermanent}
                    onChange={() => setShowPermanent(!showPermanent)}
                  />
                  <span className='ms-2'>Permanent Keys</span>
                </label>
                <label className='me-3 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    checked={showTemporary}
                    onChange={() => setShowTemporary(!showTemporary)}
                  />
                  <span className='ms-2'>Temporary Keys</span>
                </label>
                <label className='me-3 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    checked={showPerpetual}
                    onChange={() => setShowPerpetual(!showPerpetual)}
                  />
                  <span className='ms-2'>Perpetual Keys</span>
               </label>
                {/*  <label className='me-3 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    checked={showAddedKeys}
                    onChange={() => setShowAddedKeys(!showAddedKeys)}
                  />
                  <span className='ms-2'>Added Keys</span> 
                </label> */}
              </div>
            </MDBCol>
          </MDBRow>
          <DataTable
            pagination
            paginationPerPage={5} 
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            noDataComponent={<div>No data available</div>}
          />
          <div className='m-3 pe-3 d-flex justify-content-start'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => { onClose(); onCloseAndOpenAddKey(); }}
            >
              Back
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};


const AddKeyPopup = (props) => {

  let variant = 'success'

  const [availableKeys, setAvailableKeys] = useState(0);
  const [availableFree, setAvailableFree] = useState(0);
  const [perpetualAvailable, setPerpetualAvailable] = useState(0);
  const [myAvailable, setMyAvailable] = useState(0);
  const [myFree, setMyFree] = useState(0);
  const [myPerpetual, setMyPerpetual] = useState(0);
  const [addKeys, setAddKeys] = useState('0');
  const [addFree, setAddFree] = useState('0');
  const [addPerpetual, setAddPerpetual] = useState('0');
  const [keyWallet, setKeyWallet] = useState([]);

  const [validAddKey, setValidAddKey] = useState(false);
  const [validTempKey, setValidTempKey] = useState(false);
  const [validPerKey, setValidPerKey] = useState(false);
  const [zeroOrEmpty, setZeroOrEmpty] = useState(false)
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const availableTot = availableKeys+availableFree+perpetualAvailable;
  const [addTot, setAddTot] = useState(addKeys+addFree+addPerpetual);
  const keyTot = availableKeys+addKeys;
  const freeTot = availableFree+addFree;
  const perpetualTot = perpetualAvailable+addPerpetual;
  const finalTot = availableTot + addTot ;

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [sentRequest, setSendRequest] = useState(false)

  //Get data for available key and available free key 
  const fetchKeyWallet = async () => {
    try {
      const response = await axios.get(KEY_WALLET_URL); 
      const data = response.data;
      const status = data.status;
      const result = data.result;  
      //console.log(result)     
      if (status) {
          setMyAvailable(result.available !== null ? result.available : 0);
          setMyFree(result.freeAvailable !== null ? result.freeAvailable : 0);
          setMyPerpetual(result.perpetualAvailable !== null ? result.perpetualAvailable : 0);
      }
      else{
        setKeyWallet([]);
      }
    } catch (err) {      
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      } 
    }
  }

  useEffect(() => {
    const fetchUserKeyWallet = async () => {
      try {
        const response = await axios.get(USER_KEY_WALLET+`${props.id}`); 
        const data = response.data;
        const status = data.status;
        const result = data.result; 
        //console.log(result)
        if (status) {
          setAvailableKeys(result.available);
          setAvailableFree(result.freeAvailable);
          setPerpetualAvailable(result.perpetualAvailable);
        }
      } catch (err) {     
        variant = 'error'
        if(!err.response){
          enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
        }else if(err.response !== 403){
          enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
        } 
      }
    }

   
    if (props.open) {
      fetchUserKeyWallet();
      fetchKeyWallet();
      //fetchHistoryData(); // Fetch history data when popup opens
    }
    /* if(props.open){
      fetchUserKeyWallet()   
      fetchKeyWallet();
    }  */
  }, [props.id, props.open])

  const fetchHistoryData = async () => {
    try {
      const response = await axios.get(HISTORY_URL + `${props.id}`); //${props.id}
      //console.log('Data', response);
      const data = response.data;
      //console.log('Data', data);
      const status = data.status;
      const result = data.result;
      
      if (status) {
        setHistoryData(result); // Assuming result is an array of history items
        //console.log('Data', historyData);
      } else {
        setHistoryData([]);
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };



  useEffect(() => {
    if (openHistoryPopup) {
      fetchHistoryData(); // Fetch history data when popup opens
      //console.log('History', historyData);
    }
  }, [openHistoryPopup]);

  const validateAddKey = () => {
    if(myAvailable < addKeys){
      setValidAddKey(false);
    }else{
      setValidAddKey(true);
    }
  }

  const validateTempKey =() => {
    if( myFree < addFree){
      setValidTempKey(false);
    }else{
      setValidTempKey(true);
    }
  }

  const validatePerpetual =() => {
    if( myPerpetual === ''|| myPerpetual < addPerpetual){
      setValidPerKey(false);
    }else{
      setValidPerKey(true);
    }
  }

  useEffect(() => {
    // set addKey and addFree when input values change
    if(addKeys !== ''){
      setAddKeys(parseInt(addKeys));
    }
    if(addFree !== ''){
      setAddFree(parseInt(addFree));
    }
    if(addPerpetual !== ''){
      setAddPerpetual(parseInt(addPerpetual));
    }
    //set the add Total when addKeys and addFree change
    if(addKeys === '' && addFree === '' && addPerpetual === '' )  {
      setAddTot(0+0+0);
    }else if(addKeys === '' && addFree !== '' && addPerpetual === '')  {
      setAddTot(0+addFree+0);
    }else if(addKeys !== '' && addFree === '' && addPerpetual === '')  {
      setAddTot(addKeys+0+0);
    }else if(addKeys !== '' && addFree === '' && addPerpetual !== '')  {
      setAddTot(addKeys+0+addPerpetual);
    }else if(addKeys === '' && addFree !== '' && addPerpetual !== '')  {
      setAddTot(0+addFree+addPerpetual);
    }else if(addKeys === '' && addFree === '' && addPerpetual !== '')  {
      setAddTot(0+0+addPerpetual);
    }else if(addKeys !== '' && addFree !== '' && addPerpetual === '')  {
      setAddTot(addKeys+addFree+0);
    }
    else{
      setAddTot(addKeys+addFree+addPerpetual);
    }

   
    //validate if one fields is empty and one is 0
    if(addKeys === '' && addFree === '' && addPerpetual === '')  {
      setZeroOrEmpty(false)
    }else if(addKeys === '' && addFree === 0 && addPerpetual === ''){
      setZeroOrEmpty(false)
    }else if(addKeys === 0 && addFree === '' && addPerpetual === 0){
      setZeroOrEmpty(false)
    //validate if both fields are 0
    }else if(addKeys === 0 && addFree === 0 && addPerpetual === 0){
      setZeroOrEmpty(false)
    }else {
      setZeroOrEmpty(true)
    }  
    //validate if add keys exceeded available keys
    if(myAvailable < addKeys){
      setValidAddKey(false);
      //console.log('addKeys big')
    }else{
      setValidAddKey(true);
    }
    //validate if add keys exceeded temporary keys
    if( myFree < addFree){
      setValidTempKey(false);
    }else{
      setValidTempKey(true);
    }
    if( myPerpetual < addPerpetual){
      setValidPerKey(false);
    }else{
      setValidPerKey(true);
    }
  }, [addKeys, addFree, myAvailable, myPerpetual, addPerpetual, myFree, zeroOrEmpty, validAddKey]) 

  const handleAllocateKeys = async () => {
    setSendRequest(true)
    const newData = {available:addKeys, freeAvailable:addFree, perpetualAvailable:addPerpetual,
      user: props.id}
    try {
      const response = await axios.post(KEY_ALLOCATE_URL, newData);
      setSendRequest(false)
      const status= response.data.status;
      const result = response.data.result;
      if(status){
        setAddKeys(0)
        setAddFree(0)
        setAddPerpetual(0)
        props.setOpen(false);
        variant = 'success'
        enqueueSnackbar('Key Allocation Successful!', {variant}) 
      }else{
        variant = 'error'
        enqueueSnackbar('Key Allocation Failed!', {variant})
      }
    } catch (err) {     
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar('Key Allocation Failed!', {variant}) 
      } 
    }
  }

  const handleKeyDown = (event) => {
    //check if the key pressed is not a number or a control key
    if (!((event.keyCode >= 48 && event.keyCode <= 57) 
        || (event.keyCode >= 96 && event.keyCode <= 105) 
        || event.keyCode === 8 || event.keyCode === 9 
        || event.keyCode === 37 || event.keyCode === 39)) {
      event.preventDefault();
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if(event === 'close'){
      return;
    }
    setAddFree('0')
    setAddKeys('0')
    setAddPerpetual('0')
    props.setOpen(false);
  }

  const handlePopupClose = () => {
    
    setAddKeys('0');
    setAddFree('0');
    setAddPerpetual('0')
    props.setOpen(false);
    
  };

  const handleViewHistory = async () => {
    props.setOpen(false);
    await fetchHistoryData();
    setOpenHistoryPopup(true);
  };

  const handleHistoryPopupOpen = () => {
    setOpenHistoryPopup(false);
    props.setOpen(true); // Reopen AddKeyPopup
  };

  return (
    <div className={props.open ? 'glass-effect' : ''}>             
      <SnackbarAlerts 
        open={openAlert} 
        setOpen={setOpenAlert} 
        msg={alertMsg} 
        severity={severity}
      />
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        >   
          <div className='table-div'> 
            <div className='end-div p-0'>
              <GrClose onClick= {handlePopupClose} />
            </div> 
              <MDBRow className='m-3'>                  
              <MDBCol md='12' >
                <h6 className='card-text mb-3'>Allocating License to : {props.customerName}</h6>
              </MDBCol>
              </MDBRow>      
              <MDBRow className='m-3 mb-0'>                  
              <MDBCol md='4' >
                <h6 
                  className={!validAddKey ? 'validate-h6 mb-0' :'card-text'}
                >
                  Permanent License (User) : {myAvailable}
                </h6>  
                <p className={!validAddKey ? "validate" : "notVisible"}>                                    
                    Maximum number should be {myAvailable}
                </p> 
              </MDBCol>
              <MDBCol md='4' >
                <h6 
                  className={!validPerKey ? 'validate-h6 mb-0' :'card-text'}
                >
                  Perpetual License (User) : {myPerpetual}
                </h6>  
                <p className={!validPerKey ? "validate" : "notVisible"}>                                    
                    Maximum number should be {myPerpetual}
                </p> 
              </MDBCol>
              <MDBCol md='4'>               
                <h6
                  className={!validTempKey ? 'validate-h6 mb-0' :'card-text'}
                >
                  Temporary License (User) : {myFree}
                </h6>   
                <p className={!validTempKey ? "validate" : "notVisible"}>                                    
                  Maximum number should be {myFree}
                </p>
              </MDBCol>
              </MDBRow>      
            <TableContainer 
              component={Paper}
              style={{boxShadow: "0px 13px 20px 0px #80808029"}}
            >
              <Table sx={{minWidth: 1000, maxWidth: 1000}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">License Type</TableCell>
                      <TableCell align="left">Available</TableCell>
                      <TableCell sx={{width: '30%'}} align="left">Add</TableCell>
                      <TableCell align="left">Final Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{color: "white"}}>
                    <TableRow>
                      <TableCell align="left">Permanent</TableCell>
                      <TableCell align="left">{availableKeys}</TableCell>
                      <TableCell sx={{width: '30%'}} align="left">
                        <Form.Control 
                          className={!validAddKey ? 'table-input not-valid' : 'table-input'}
                          type='number'
                          min={0} 
                          max={keyWallet.available}
                          id='descriptionId'
                          onChange={(e) => setAddKeys(e.target.value)}
                          onKeyDown={handleKeyDown} 
                          value={addKeys}        
                          onBlur={validateAddKey}    
                        /> 
                      </TableCell>
                      <TableCell align="left">{keyTot}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Perpetual</TableCell>
                      <TableCell align="left">{perpetualAvailable}</TableCell>
                      <TableCell sx={{width: '30%'}} align="left">
                        <Form.Control 
                          className='table-input'
                          type='number' 
                          min={0} 
                          max={keyWallet.perpetualAvailable}
                          id='descriptionId'
                          onChange={(e) => setAddPerpetual(e.target.value)}
                          onKeyDown={handleKeyDown} 
                          value={addPerpetual}  
                          onBlur={validatePerpetual}    
                        />
                      </TableCell>
                      <TableCell align="left">{perpetualTot}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Temporary</TableCell>
                      <TableCell align="left">{availableFree}</TableCell>
                      <TableCell sx={{width: '30%'}} align="left">
                        <Form.Control 
                          className='table-input'
                          type='number' 
                          min={0} 
                          max={keyWallet.freeAvailable}
                          id='descriptionId'
                          onChange={(e) => setAddFree(e.target.value)}
                          onKeyDown={handleKeyDown} 
                          value={addFree}  
                          onBlur={validateTempKey}    
                        />
                      </TableCell>
                      <TableCell align="left">{freeTot}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Total</TableCell>
                      <TableCell align="left">{availableTot}</TableCell>
                      <TableCell sx={{width: '30%'}} align="left">&nbsp;&nbsp;&nbsp;{addTot}</TableCell>
                      <TableCell align="left">{finalTot}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='m-3 pe-3 d-flex justify-content-between'>
                
            <Button
              variant='contained'
              color='success'
              onClick={handleViewHistory}
            >
              View History
            </Button>
            
            <Button 
              variant="contained"
              onClick={handleAllocateKeys}  
              disabled={!validAddKey || !validTempKey || !validPerKey || !zeroOrEmpty ? true : false}                
            >
              Allocate License
            </Button>
          </div>
            </TableContainer>
          </div> 
      </Popover>
      
      <HistoryPopup
        open={openHistoryPopup}
        onClose={() => setOpenHistoryPopup(false)}
        onCloseAndOpenAddKey={handleHistoryPopupOpen}
        historyData={historyData}
      />
    
    </div>
  );
}

export default AddKeyPopup